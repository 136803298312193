import React, { useState } from "react";
import logo from "../../src/Mcgans.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useTheme } from "../togglethemebar/ThemeContext";

export const Header = () => {
  // const { theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  // header Apply
  const [showDropdown, setShowDropdown] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
    setShowDropdown(false); // Hide dropdown after clicking
  };
  return (
    <>
      {/* <div className={theme === "dark" ? "header-dark" : "header-light"}> */}

      {/* <!--Start Top Header Area--> */}

      <div className="top-header-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <div className="social-media socialmediaheader">
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <li
                    className="socialmediaheader"
                    style={{ marginRight: "10px" }}
                  >
                    <span>Follow Us On</span>
                  </li>{" "}
                  <li style={{ marginRight: "10px" }}>
                    <a
                      href="https://www.linkedin.com/in/archmcgan-ooty-2b1897304?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="ri-linkedin-fill"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                  </li>
                  <li style={{ marginRight: "10px" }}>
                    <a
                      href="https://x.com/mcgansarch?t=2DUahM0qDkcR8Q3HJFR94g&s=08"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-twitter-fill socialmediaheader"></i>
                    </a>
                  </li>
                  <li style={{ marginRight: "10px" }}>
                    <a
                      href="https://www.youtube.com/@mcgansooty3246"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ri-youtube-fill socialmediaheader"></i>
                    </a>
                  </li>
                  <li style={{ marginRight: "10px" }}>
                    <a
                      href="https://www.instagram.com/mcgans_arch?igsh=c3lmN3pkc3dhajVq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="ri-instagram-line"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                  </li>
                  <li style={{ marginRight: "10px" }}>
                    <a
                      href="https://www.facebook.com/share/1BTYPqWFPi/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="ri-facebook-fill"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-8 col-md-8">
              <div className="header-right-content">
                <div className="list">
                  <ul>
                    <li>
                      <Link to="/alumni">Alumni</Link>
                    </li>
                    <li>
                      <Link to="/campus" className="nav-link">
                        Campus
                      </Link>
                    </li>
                    <li>
                      <Link to="/staff" className="nav-link">
                        Faculty & Staff
                      </Link>
                    </li>
                    <li>
                      <Link to="/carrers" className="nav-link">
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link to="/student-story-details" className="nav-link">
                        Student Story
                      </Link>
                    </li>
                    <li>
                      <a href="tel:9344511168">
                        <i className="bi bi-telephone-fill"></i> &nbsp;+91 93445
                        11168
                      </a>
                    </li>
                    <li className="position-relative">
                      <button
                        className="default-btnn "
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        Apply
                      </button>

                      {/* Apply Dropdown Menu */}
                      {showDropdown && (
                        <div className="apply-dropdown">
                          <button
                            className="mt-1 Applyheader"
                            onClick={() => handleNavigate("/barch-application")}
                          >
                            B.Arch
                          </button>
                          <button
                            className="Applyheader1"
                            onClick={() => handleNavigate("/march-application")}
                          >
                            M.Arch
                          </button>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-area nav-bg-1">
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu">
              <div className="logo">
                <Link to="/" className="nav-link">
                  <img
                    src="assets/images/Mcgan's-logo.svg"
                    className="main-logo"
                    title="logo"
                    alt="logo"
                  />
                  <img
                    src="assets/images/Mcgan's-logo.svg"
                    className="white-logo"
                    title="white-logo"
                    alt="white-logo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="desktop-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
                <img
                  src="assets/images/Mcgan's-logo.svg"
                  title="Mcgan's-logo"
                  alt="Mcgan's-logo"
                />
              </Link>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto">
                  <li className={`nav-item ${isActive("/") ? "active" : ""}`}>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      isActive("/about-us") || isActive("/achievements")
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/about-us" className="nav-link">
                      About
                    </Link>

                    <ul className="dropdown-menu">
                      <li
                        className={`nav-item ${
                          isActive("/about-us") ? "active" : ""
                        }`}
                      >
                        <Link to="/about-us" className="nav-link">
                          About
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          isActive("/achievements") ? "active" : ""
                        }`}
                      >
                        <Link to="/achievements" className="nav-link">
                          Achievements
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={`nav-item ${
                      isActive("/undergraduate") ||
                      isActive("/postgraduate") ||
                      isActive("/diploma") ||
                      isActive("/international-student")
                        ? "active"
                        : ""
                    }`}
                  >
                    <a href="#" className="nav-link dropdown-toggle">
                      Academic
                    </a>

                    <ul className="dropdown-menu">
                      <li
                        className={`nav-item ${
                          isActive("/undergraduate") ? "active" : ""
                        }`}
                      >
                        <Link to="/undergraduate" className="nav-link">
                          Undergraduate
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          isActive("/postgraduate") ? "active" : ""
                        }`}
                      >
                        <Link to="/postgraduate" className="nav-link">
                          Postgraduate
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          isActive("/diploma") ? "active" : ""
                        }`}
                      >
                        <Link to="/diploma" className="nav-link">
                          Diploma
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          isActive("/international-student") ? "active" : ""
                        }`}
                      >
                        <Link to="/international-student" className="nav-link">
                          International Student
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={`nav-item ${
                      isActive("/gallery") ? "active" : ""
                    }`}
                  >
                    <Link to="/gallery " className="nav-link">
                      Gallery
                    </Link>
                  </li>

                  <li
                    className={`nav-item ${
                      isActive("/placement") ? "active" : ""
                    }`}
                  >
                    <Link to="/placement " className="nav-link">
                      Placement
                    </Link>
                  </li>

                  <li
                    className={`nav-item ${
                      isActive("/news") || isActive("/events") ? "active" : ""
                    }`}
                  >
                    <a href="#" className="nav-link dropdown-toggle">
                      News & Events
                    </a>

                    <ul className="dropdown-menu">
                      <li
                        className={`nav-item ${
                          isActive("/news") ? "active" : ""
                        }`}
                      >
                        <Link to="/news" className="nav-link">
                          News
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          isActive("/events") ? "active" : ""
                        }`}
                      >
                        <Link to="/events" className="nav-link">
                          Events
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={`nav-item ${
                      isActive("/contact") ? "active" : ""
                    }`}
                  >
                    <Link to="/contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>

                <div className="others-options">
                  <div className="icon">
                    <i
                      className="ri-menu-3-fill"
                      data-bs-toggle="modal"
                      data-bs-target="#sidebarModal"
                    ></i>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="others-option-for-responsive">
          <div className="container">
            <div className="dot-menu">
              <div className="inner">
                <div className="icon">
                  <i
                    className="ri-menu-3-fill"
                    data-bs-toggle="modal"
                    data-bs-target="#sidebarModal"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- End Navbar Area --> */}

      {/* <!-- Sidebar Modal --> */}
      <div
        className="sidebarModal modal right fade"
        id="sidebarModal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal">
              <i className="ri-close-line"></i>
            </button>

            <div className="modal-body">
              <a>
                <img
                  src="assets/images/Mcgan's-logo.svg"
                  width="186px"
                  height="58px"
                  className="main-logo"
                  title="main-logo"
                  alt="main-logo"
                />
              </a>
              <div className="sidebar-content">
                <h3>About Us</h3>
                <p>
                  McGAN'S Ooty School of Architecture aims to inspire socially
                  responsible leaders through a progressive, interdisciplinary
                  curriculum that nurtures creativity, critical thinking, and
                  community engagement in a breathtaking environment.
                </p>

                <div className="sidebar-btn">
                  <Link to="/about-us" className="nav-link">
                    <button type="button" className="default-btn btn btnfamily">
                      About Us
                    </button>
                  </Link>
                </div>
              </div>
              <div className="sidebar-contact-info">
                <h3>Contact Information</h3>

                <ul className="info-list">
                  <li>
                    <i className="ri-phone-fill"></i>{" "}
                    <a href="tel:9488336599">+91 94883 36599</a>
                  </li>

                  <li>
                    <i className="ri-mail-line"></i>
                    <a href="mailto:mcgansoffice@gmail.com">
                      mcgansoffice@gmail.com
                    </a>
                  </li>

                  <li>
                    <i className="ri-map-pin-line"></i>McGAN'S Ooty School of
                    Architecture Perar, Kotagiri Road, Ooty, The Nilgiris,
                    Tamilnadu - 643002
                  </li>
                </ul>
              </div>
              <ul className="sidebar-social-list">
                <li>
                  <a
                    href="https://www.facebook.com/share/1BTYPqWFPi/"
                    target="_blank"
                  >
                    <i className="flaticon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com" target="_blank">
                    <i className="flaticon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://linkedin.com/?lang=en" target="_blank">
                    <i className="flaticon-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/mcgans_arch?igsh=c3lmN3pkc3dhajVq"
                    target="_blank"
                  >
                    <i className="flaticon-instagram"></i>
                  </a>
                </li>
              </ul>
              <div className="contact-form">
                <h3>Ready to Get Started?</h3>

                <form id="contactForm">
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          required
                          data-error="Please enter your name"
                          placeholder="Your name"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          required
                          data-error="Please enter your email"
                          placeholder="Your email address"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone_number"
                          className="form-control"
                          required
                          data-error="Please enter your phone number"
                          placeholder="Your phone number"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          cols="30"
                          rows="6"
                          required
                          data-error="Please enter your message"
                          placeholder="Write your message..."
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="default-btn">
                        Send Message<span></span>
                      </button>
                      <div
                        id="msgSubmit"
                        className="h3 text-center hidden"
                      ></div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Sidebar Modal --> */}
      {/* </div> */}
    </>
  );
};
