import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getCareerssView } from "../server/apihelper";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel";

// Ensure jQuery is available globally
window.jQuery = $;

export const CarrersDetails = () => {
  const [carrersView, setCarrersView] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init();
  }, []);

  // Fetch news details based on the ID from URL params
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCareerssView(id);
        setCarrersView(res.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchData();
  }, [id]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-"); // Replace spaces with dashes
  };

  return (
    <>
      {/* Start Page Banner */}
      <div className="page-banner-area bg-2">
        <div className="container">
          <div className="page-banner-content">
            <h1>Careers Details</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">
                <Link to="/carrers">Careers</Link>
              </li>
              <li className="navspace">Careers Details</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* Start Health Care Details */}
      <div class="campus-information-area pb-70">
        <div class="container">
          <div class="row align-items-center mt-5">
            <h2 className="CarrersDetails1">Careers Details</h2>
            {carrersView ? (
              <div class="col-lg-12">
                <div class="campus-content style-2">
                  <div class="campus-title">
                    <h3>{carrersView.job_title}</h3>
                  </div>
                  <div class="list">
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <ul>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Qualification : </strong>
                              <span>{carrersView.qualification}</span>
                            </p>
                          </li>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Experience Min : </strong>
                              <span>{carrersView.experience_min}</span>
                            </p>
                          </li>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Duration : </strong>
                              <span>{carrersView.duration}</span>
                            </p>
                          </li>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Last Date : </strong>
                              <span>{formatDate(carrersView.last_date)}</span>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <ul>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Place : </strong>
                              <span>{carrersView.place}</span>
                            </p>
                          </li>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Experience Max : </strong>
                              <span>{carrersView.experience_max}</span>
                            </p>
                          </li>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Posted On : </strong>
                              <span>{formatDate(carrersView.posted_on)}</span>
                            </p>
                          </li>
                          <li>
                            <i class="ri-check-fill"></i>
                            <p>
                              <strong>Notes : </strong>{" "}
                              <span>{carrersView.notes}</span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h4>Description</h4>

                  <p class="text-justify">{carrersView.job_description}</p>

                  <div className="student-details col-lg-3 col-sm-6 mt-3">
                    <button
                      className="btn carrer-button"
                      onClick={() => navigate(`/carrers`)}
                    >
                      <span>Back</span>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <span>"No Data"</span>
            )}
          </div>
        </div>
      </div>

      {/* End Health Care Details */}
    </>
  );
};
