import welcome from "../../src/images/mcgans.png";
import MyCarousel from "./owlcarousel";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import { Link, useParams } from "react-router-dom";
import { limitNews, limitStudentStories } from "../server/apihelper";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";

export const Main = () => {
  const { id } = useParams();
  const [experienceCount, setExperienceCount] = useState(0);
  const [studentsCount, setStudentsCount] = useState(0);
  const [nationalitiesCount, setNationalitiesCount] = useState(0);
  const [studentStoriesLimit, setStudentStoriesLimit] = useState([]);
  const [newsLimit, setlimitNews] = useState([]);
  const items_per_page = 3;
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    const query = "page=1&items_per_page=3";
    limitStudentStories(query).then((res) => {
      setStudentStoriesLimit(res.data);
    });
    // Initialize the Owl Carousel
    const $ = window.$; // Ensure jQuery is available
    $(".campus-slider").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      responsive: {
        0: { items: 1 },
        600: { items: 2 },
        1000: { items: 3 },
      },
    });

    // Counter animation function
    const animateCounter = (target, setState) => {
      const speed = 100; // Speed of the counter animation
      const increment = target / speed;
      let currentCount = 0;

      const updateCounter = () => {
        currentCount += increment;
        if (currentCount < target) {
          setState(Math.ceil(currentCount));
          setTimeout(updateCounter, 30);
        } else {
          setState(target);
        }
      };

      updateCounter();
    };

    // Trigger the counter animations
    animateCounter(15, setExperienceCount);
    animateCounter(30, setStudentsCount); // 30k students
    animateCounter(52, setNationalitiesCount);
  }, []);
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    const query = "page=1&items_per_page=3";
    limitNews(query).then((res) => {
      setlimitNews(res.data);
    });
  }, []);
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Best Architecture Colleges in Tamil Nadu | Top B.Arch Colleges in
            India | Architecture Courses Under Anna University
          </title>
          <meta
            name="description"
            content="Top B.Arch colleges in India and the best architecture colleges in Tamil Nadu offer accredited architecture courses under Anna University. Enroll in the best architecture institutes in India and Tamil Nadu to pursue a rewarding career with world-class education and expert guidance."
          />
          <meta
            name="keywords"
            content="Best architecture colleges in Tamil Nadu,
Top architecture colleges in India,
Architecture courses under Anna University,
B.Arch colleges in Tamil Nadu,
Architecture colleges in Coimbatore,
Architecture institutes in Ooty,
Top 10 architecture colleges in Tamil Nadu,
Best B.Arch colleges near Ooty,
Council of Architecture recognized colleges,
Colleges offering NATA coaching in Tamil Nadu,
Top-rated architecture programs in Tamil Nadu,
Affordable architecture colleges in South India,
Best design colleges in Tamil Nadu,
Architecture courses in the Nilgiris,
Colleges for architecture in Tamil Nadu,
Best colleges for B.Arch in Tamil Nadu,
Architecture colleges affiliated with Anna University,
Architecture institutes in South India,
Architecture entrance exam coaching (NATA, CEPT),
Architecture course details in Tamil Nadu,
Leading architecture colleges near Coimbatore,
Modern architecture colleges in Ooty,
Architecture colleges in hill stations,
Colleges for architecture in the Nilgiris,
Best educational institutions for architecture in India,
Architecture colleges in Ooty,
Top B.Arch colleges in the Nilgiris,
Best design institutes in Ooty,
Architecture education in Ooty hill station,
Colleges near Ooty for architecture courses,
Leading architecture colleges in Tamil Nadu’s hill areas"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Top B.Arch Colleges in Tamil Nadu and Leading Architecture Institutes
          in India
        </h1>
      </div>

      {/* <!--Start Banner Area--> */}
      <div className="banner-area">
        <div className="container-fluid">
          <MyCarousel />
        </div>
      </div>

      {/* <!--End Banner Area--> */}
      {/* Start Campus Information */}
      <div className="campus-information-area pb-70">
        <div className="container">
          <div className="row align-items-center mt-5">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div className="campus-content pr-20">
                <div className="campus-title">
                  {/* <h2>We Welcome All</h2> */}
                  <h2>Design Education at McGAN’S</h2>
                  <p className="text-justify">
                    McGAN’S is a leading architecture and design school in
                    India, committed to fostering creativity through hands-on
                    learning. The curriculum blends theory and practical skills,
                    preparing students for real-world architectural challenges.
                    With a focus on sustainability, collaboration, and
                    innovation, McGAN’S shapes future leaders in the field.
                  </p>
                </div>

                <div className="counter">
                  <div className="row">
                    <div className="col-lg-4 col-4">
                      <div className="counter-card">
                        <h3>
                          <span>{experienceCount}</span>
                          <span className="target">+</span>
                        </h3>
                        <p>Years Of Experience</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-4">
                      <div className="counter-card">
                        <h3>
                          <span>{studentsCount}</span>
                          <span className="target heading-color">k</span>
                          <span className="target">+</span>
                        </h3>
                        <p>Global Students</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-4">
                      <div className="counter-card">
                        <h3>
                          <span>{nationalitiesCount}</span>
                          <span className="target">+</span>
                        </h3>
                        <p>Student Nationalities</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Link to="/about-us" className="default-btn btn">
                  Read More <i className="flaticon-next"></i>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div className="campus-image pl-20">
                <img
                  src="assets/images/welcome-page/welcome.jpg"
                  style={{ width: "526px", height: "378px" }}
                  title="welcome"
                  alt="welcome"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Campus Information */}

      {/* Start Academic Area */}
      <div class="academic-area pt-60 pb-70">
        <div class="container">
          <div class="section-title">
            <h2>Academics</h2>
            <p>
              McGAN’S offers students a rigorous academic experience in a
              collaborative and supportive environment
            </p>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <div class="single-academics-card">
                <div className="academic-top-content topAcademic">
                  <i class="flaticon-college-graduation"></i>
                  <p>
                    <h3>Diploma in Architecture</h3>
                  </p>
                </div>
                <p class="text-justify">
                  McGAN'S Ooty School of an Architecture provides a practical
                  and immersive Diploma the in Architecture, developing core
                  creative skills for future architects.
                </p>
                <Link to="/diploma" className="read-more-btn">
                  Diploma<i className="flaticon-next"></i>
                </Link>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400"
              data-aos-once="true"
            >
              <div class="single-academics-card">
                <div class="academic-top-content topAcademic">
                  <i class="flaticon-graduation"></i>

                  <p>
                    <h3>Undergraduate</h3>
                  </p>
                </div>
                <p class="text-justify">
                  A comprehensive architecture program fostering creativity,
                  innovation, and leadership, blending design with technology,
                  theory, and history.
                </p>
                <Link to="/undergraduate" className="read-more-btn">
                  Undergraduate<i className="flaticon-next"></i>
                </Link>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <div class="single-academics-card">
                <div class="academic-top-content topAcademic">
                  <i class="flaticon-college-graduation"></i>
                  <p>
                    <h3>Post Graduate</h3>
                  </p>
                </div>
                <p class="text-justify">
                  Advanced training in Historic Preservation, Community Design,
                  and Visualization, offering practical experience and
                  cutting-edge resources.
                </p>
                <Link to="/postgraduate" className="read-more-btn">
                  Post Graduate<i className="flaticon-next"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Academic Area */}

      {/* <!--Start Education Area--> */}
      <div className="eduction-area pb-70">
        <div className="container-fluid p-0 mt-5">
          <div className="section-title">
            <h2>Why McGAN’S</h2>
            <p className="text-justify whystyle">
              State-of-the-Art Facilities Access top-notch facilities and
              resources for an enhanced learning experience
            </p>
          </div>
          <div className="row g-0">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <div className="single-education-card bg-1">
                <div className="edication-content">
                  <div className="icon">
                    <i className="flaticon-college"></i>
                  </div>
                  <p>
                    <h3>Located In Panoramic Valley Of Ooty</h3>
                  </p>
                  <a
                    // href="academics-details.html"
                    className="read-more-btn white-color"
                  ></a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400"
              data-aos-once="true"
            >
              <div className="single-education-card bg-2">
                <div className="edication-content">
                  <div className="icon">
                    <i className="flaticon-student-with-graduation-cap"></i>
                  </div>
                  <p>
                    <h3>15 Years Strong Commitment To Teaching</h3>
                  </p>
                  <a
                    // href="academics-details.html"
                    className="read-more-btn white-color"
                  ></a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <div className="single-education-card bg-3">
                <div className="edication-content">
                  <div className="icon">
                    <i className="flaticon-college-graduation"></i>
                  </div>
                  <p>
                    <h3>All India Ranking By Outlook #8 </h3>
                  </p>
                  <a
                    // href="academics-details.html"
                    className="read-more-btn white-color"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Education Area--> */}

      {/* <!--Start Campus Information--> */}
      <div className="campus-information-area pb-70">
        <div className="section-title">
          <h2>Students Story</h2>
          <p>Journeys of Aspiration Our Students Transformative Experiences</p>
        </div>
        <div className="container">
          {studentStoriesLimit.map((story, index) => (
            <div className="container" key={index}>
              <div className="row align-items-center mt-5">
                {index % 2 === 0 ? (
                  <>
                    <div className="col-lg-6">
                      <div className="campus-content pr-20">
                        <div className="campus-title">
                          <h4>{story.title}</h4>
                          <p className="text-justify">{story.description}</p>
                          <p>
                            <b>{story.student_name}</b>
                            <br /> {story.year}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="campus-image pl-20">
                        <img
                          src={story.image}
                          style={{ width: "450px", height: "400px" }}
                          title="StudentStory"
                          alt="Student Story"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6">
                      <div className="campus-image pl-20">
                        <img
                          src={story.image}
                          style={{ width: "450px", height: "400px" }}
                          title="StudentStory1"
                          alt="Student Story1"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="campus-content pr-20">
                        <div className="campus-title">
                          <h4>{story.title}</h4>
                          <p className="text-justify">{story.description}</p>
                          <p>
                            <b>{story.student_name}</b>
                            <br /> {story.year}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}

          {/* Load More Button */}
          {studentStoriesLimit.length >= items_per_page && (
            <div className="content studentstory">
              <Link
                to="/student-story-details"
                className="read-more-btn active studentstory"
              >
                More on Students Story
                <i className="flaticon-next"></i>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* <!--End Campus Information--> */}

      {/* <!--Start Campus Life Area--> */}
      <div class="campus-life-area ptb-50 Campus">
        <div class="container">
          <div class="section-title">
            <h2>Campus Life</h2>
            <p>Thriving Together In a lively Campus Community. </p>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <div class="single-campus-card card-height  Campus">
                <div class="img">
                  <Link to="/campus-studiodetails">
                    <img
                      src="assets/images/campus-life/lab.png"
                      style={{ width: "365px", height: "258px" }}
                      title="Campus"
                      alt="lab"
                    />
                  </Link>
                </div>
                <div class="campus-content">
                  <Link to="/campus-studiodetails">
                    <span>Computer Studio</span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400"
              data-aos-once="true"
            >
              <div class="single-campus-card card-height">
                <div class="img">
                  <Link to="/campus-librarydetails">
                    <img
                      src="assets/images/campus-life/library.png"
                      style={{ width: "365px", height: "258px" }}
                      title="Campus1"
                      alt="library"
                    />
                  </Link>
                </div>
                <div class="campus-content">
                  <Link to="/campus-librarydetails">
                    <span>Library</span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <div class="single-campus-card card-height">
                <div class="img">
                  <Link to="/campus-hosteldetails">
                    <img
                      src="assets/images/campus-life/Homehostel.png"
                      style={{ width: "365px", height: "258px" }}
                      title="Hostel"
                      alt="hostel"
                    />
                  </Link>
                </div>
                <div class="campus-content">
                  <Link to="/campus-hosteldetails">
                    <span>Hostel</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="more-health-care text-center">
          <p>
            <Link to="/campus" className="read-more-btn active">
              More on Campus Life
              <i className="flaticon-next"></i>
            </Link>
          </p>
        </div>
      </div>
      {/* <!--End Campus Life Area--> */}

      {/* <!--Start Lates News Area--> */}
      <div className="lates-news-area ptb-100 bg-f4f6f9">
        <div className="container">
          <div className="section-title">
            <h2>News</h2>
            <p>Expand Your Knowledge At Our Interactive Learning Center</p>
          </div>

          <OwlCarousel className="news-slider owl-theme" {...options}>
            {newsLimit.map((items, index) => (
              <div className="single-news-card style2">
                <div className="news-img" key={index}>
                  <Link
                    to={`/news-details/${items?.primaryId}`}
                    className="read-more-btn"
                  >
                    <img
                      src={items.image}
                      style={{ width: "365px", height: "258px" }}
                      title="Cultural"
                      alt="Cultural Fest 2023"
                    />
                  </Link>
                </div>
                <div className="news-content">
                  <Link
                    to={`/news-details/${items?.primaryId}`}
                    className="news-title-link"
                  >
                    <h3>{items.title}</h3>
                  </Link>
                  {items.description.split(" ").slice(0, 8).join(" ")}
                  <Link
                    to={`/news-details/${items?.primaryId}`}
                    className="read-more-btn"
                  >
                    <span className="ReadMoreStyle"> Read More</span>
                    <i className="flaticon-next"></i>
                  </Link>
                </div>
              </div>
            ))}
            {/* Add more news items similarly */}
          </OwlCarousel>
          <div class="more-health-care text-center">
            <p>
              <Link to="/news" className="read-more-btn active">
                More on News
                <i className="flaticon-next"></i>
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* <!--End News Area--> */}

      {/* <!--Start Faq Area--> */}
      <div class="faq-area  ptb-50 " style={{ display: "none" }}>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="faq-image pr-20">
                <img
                  src="assets/images/faq-img.jpg"
                  style={{
                    width: "1270px",
                    height: "1370px",
                  }}
                  title="FAQ"
                  alt="FAQ"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="faq-left-content pl-20">
                <div class="faq-title">
                  <h2>Need To Ask Some Questions Or Check Questions</h2>
                  <p>
                    Our Academic FAQ section provides essential information on
                    admissions, courses, examinations, and academic policies. It
                    covers details on eligibility, application procedures,
                    grading systems, and available scholarships.
                  </p>
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1. What is the eligibility for B.Arch?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Candidates must have completed 12th grade with
                        Mathematics as a subject, or a 3-year diploma in any
                        stream with Mathematics.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. What is the B.Arch course duration?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        The B.Arch course is a 5-year undergraduate program.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. How can I get admission to B.Arch?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Admissions are based on entrance exams like NATA, JEE
                        Paper 2, or through institutional exams.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. What is the difference between B.Arch and B.Des?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        B.Arch focuses on architecture and building design,
                        while B.Des focuses on design in fields like fashion and
                        graphic design.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. What is the average salary for architects in India?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Freshers can earn between ₹3-6 lakhs per year, depending
                        on experience and location.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        6. What are the best architecture colleges in India?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Top colleges include IIT Roorkee, CEPT University, and
                        McGAN’S Ooty School of Architecture
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        7. Can I pursue B.Arch without a maths background?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        No, Mathematics is a mandatory subject for B.Arch
                        admissions.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        8. What is NATA?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        NATA (National Aptitude Test in Architecture) is an
                        entrance exam for admission to architecture colleges in
                        India.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        9. Can I study architecture abroad?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Yes, many universities abroad offer architecture
                        courses, but eligibility and entrance exams vary by
                        country.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        10. What is the scope after completing B.Arch?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Graduates can work as architects, urban planners, or in
                        construction management, among other roles.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Faq Area--> */}
    </>
  );
};
