import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listEvents } from "../server/apihelper";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Events = () => {
  const [eventsList, setEventsList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Set the number of items per page
  const sectionRef = useRef(null); // Reference to scroll to the top of the section

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    fetchEvents(currentPage);
  }, [currentPage]);

  const fetchEvents = async (page) => {
    const query = `page=${page}&items_per_page=${itemsPerPage}`;
    const response = await listEvents(query);
    setEventsList(response.data);
    setPagination(response.payload.pagination);
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // Formats month as 'Mar', 'Apr', etc.
    return { day, month };
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= pagination.last_page) {
      setCurrentPage(page);
      // Scroll to the top of the section
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Architecture Events at McGAN’S Ooty | Premier College Activities in
            Tamil Nadu
          </title>
          <meta
            name="description"
            content="Stay updated on the latest events at McGAN’S Ooty School of Architecture. From Graduation Day 2025 to cultural celebrations, award ceremonies, and educational tours, experience a vibrant calendar of activities shaping the future of architecture."
          />
          <meta
            name="keywords"
            content="Architecture Events Tamil Nadu, 
McGAN’S Ooty Activities, 
Graduation Day 2025, 
Cultural Fest for Architecture Students, 
Award Ceremonies Tamil Nadu, 
Educational Tours for Architecture, 
Premier Architecture College Events"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/events" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Architecture Events at McGAN’S Ooty School of Architecture
        </h1>
      </div>
      {/* Start Page Banner */}
      <div className="page-banner-area bg-2">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Events</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Events</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* Start Campus Life Area */}
      <div className="campus-life-area pt-100 pb-70" ref={sectionRef}>
        <div className="container">
          <div className="row justify-content-left">
            {/* Event Cards */}
            {eventsList.map((item, index) => {
              const { day, month } = formatDate(item.date);
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="single-events-card style-4">
                    <div className="events-image">
                      <Link
                        to={`/event-details/${item?.primaryId}`}
                        className="read-more-btn"
                      >
                        <img
                          src={item.image}
                          style={{
                            width: "356px",
                            height: "265px",
                          }}
                          title="McGAN’S Ooty News"
                          alt="McGAN’S Ooty News"
                        />
                      </Link>
                      <div className="date">
                        <span>{day}</span>
                        <p>{month}</p>
                      </div>
                    </div>
                    <div className="events-content">
                      <div className="admin">
                        <p>
                          <Link
                            to={`/event-details/${item?.primaryId}`}
                            className="read-more-btn"
                          >
                            <i className="ri-map-pin-line"></i>
                            {item.place}
                          </Link>
                        </p>
                      </div>
                      <Link
                        to={`/event-details/${item?.primaryId}`}
                        className="read-more-btn"
                      >
                        <h3>{item.title}</h3>
                      </Link>
                      <Link
                        to={`/event-details/${item?.primaryId}`}
                        className="read-more-btn"
                      >
                        Read More<i className="flaticon-next"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <ul className="pagination">
              <li
                className={`page-item ${
                  currentPage === 1 ? "disabled" : ""
                } paginationstyle`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  &laquo; Previous
                </button>
              </li>
              {Array.from(
                { length: pagination.last_page },
                (_, index) => index + 1
              ).map((page) => (
                <li
                  key={page}
                  className={`page-item ${
                    page === currentPage ? "active" : ""
                  } paginationstyle`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === pagination.last_page ? "disabled" : ""
                } paginationstyle`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next &raquo;
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Campus Life Area */}
    </>
  );
};
