import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listPlacements } from "../server/apihelper";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Placement = () => {
  const [placementsList, setPlacementsList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    const query = "page=1&items_per_page=10";
    listPlacements(query).then((res) => {
      setPlacementsList(res.data);
    });
  }, []);
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Best Architecture College Placements in Tamil Nadu | Top Career
            Opportunities at McGAN’S Ooty
          </title>
          <meta
            name="description"
            content="Best architecture college placements in Tamil Nadu at McGAN’S Ooty School of Architecture. Our dedicated Placement Cell offers career counseling, resume building, interview preparation, and internship assistance, connecting students with leading architecture firms for a successful career."
          />
          <meta
            name="keywords"
            content="Best Architecture College Placements in Tamil Nadu, 
Top Architecture Career Opportunities, 
Tamil Nadu Architecture Placement Cell, 
McGAN’S Ooty Placement Services, 
Architecture Internships Tamil Nadu, 
Career Guidance for Architecture Students, 
Job Placements for Architecture Graduates, 
Leading Recruiters for Architecture Students, 
Architecture Colleges with Highest Placements"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/placement" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Best Architecture College Placements in Tamil Nadu at McGAN’S Ooty
        </h1>
      </div>
      {/* Start Page Banner */}
      <div className="page-banner-area bg-1">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Placement</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Placement</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* <!--Start Academic Details Area--> */}
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="academics-details">
                <div className="details">
                  <h2>
                    Welcome to the Placement Cell of Mcgans Ooty school of
                    Architecture
                  </h2>
                  <p>
                    At Mcgans we take pride in shaping the future of our
                    students. Our Placement Cell is dedicated to providing
                    comprehensive support and guidance to ensure that our
                    students embark on successful career paths.
                  </p>
                </div>
                <div class="regiStartion-and-fee">
                  <h3>Services Offered</h3>
                  <div class="list">
                    <ul>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Career Counseling : Personalized guidance to help
                        students identify their strengths and career interests.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Resume Building : Workshops and individual sessions to
                        craft professional and impactful resumes.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Interview Preparation : Mock interviews and training
                        sessions to enhance interview skills and confidence.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i> Or
                        Industry Interaction : Regular seminars, webinars, and
                        networking events with industry professionals.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i> Or
                        Internship Assistance : Support in securing internships
                        that provide practical experience and industry exposure.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i> Or
                        Job Placement : Assistance in finding suitable job
                        opportunities in top firms and organizations.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- Start Academic Area --> */}
                <div className="academic-area pt-60 pb-70">
                  <div className="container">
                    <div className="text-center">
                      <h2 className="text-start">Recruitment Process</h2>
                      <p className="text-start">
                        Our placement process is designed to be seamless and
                        efficient for both students and recruiters. The process
                        includes.
                      </p>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                        data-aos-once="true"
                      >
                        <div className="single-academics-card">
                          <div className="academic-top-content text-center">
                            <img
                              src="assets/images/curriculum/curriculum3.png"
                              width="50px"
                              height="50px"
                              title="McGAN’S Ooty Placement Services"
                              alt="McGAN’S Ooty Placement Services"
                            />
                            <h3>Registration</h3>
                          </div>
                          <p>
                            Students register with the Placement Cell and submit
                            their resumes.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="400"
                        data-aos-once="true"
                      >
                        <div className="single-academics-card">
                          <div className="academic-top-content text-center">
                            <img
                              src="assets/images/curriculum/curriculum5.png"
                              width="50px"
                              height="50px"
                              title="Architecture Internships Tamil Nadu"
                              alt="Architecture Internships Tamil Nadu"
                            />
                            <h3>Aptitude Tests</h3>
                          </div>
                          <p>
                            Companies may conduct aptitude tests to shortlist
                            candidates.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="600"
                        data-aos-once="true"
                      >
                        <div className="single-academics-card">
                          <div className="academic-top-content text-center">
                            <img
                              src="assets/images/curriculum/curriculum.png"
                              width="50px"
                              height="50px"
                              title="Best Architecture College Placements in Tamil Nadu"
                              alt="Best Architecture College Placements in Tamil Nadu"
                            />
                            <h3>Group Discussions</h3>
                          </div>
                          <p>
                            Shortlisted candidates participate in group
                            discussions on relevant topics.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="600"
                        data-aos-once="true"
                      >
                        <div className="single-academics-card">
                          <div className="academic-top-content text-center">
                            <img
                              src="assets/images/curriculum/curriculum2.png"
                              width="50px"
                              height="50px"
                              title="Architecture Internships Tamil Nadu"
                              alt="Architecture Internships Tamil Nadu"
                            />
                            <h3>Pre-Placement Talks</h3>
                          </div>
                          <p>
                            Recruiters conduct pre-placement talks to provide
                            insights into their organizations and job roles.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="600"
                        data-aos-once="true"
                      >
                        <div className="single-academics-card">
                          <div className="academic-top-content text-center">
                            <img
                              src="assets/images/curriculum/curriculum4.png"
                              width="50px"
                              height="50px"
                              title="Job Placements for Architecture Graduates"
                              alt="Job Placements for Architecture Graduates"
                            />
                            <h3>Offer Letters</h3>
                          </div>
                          <p>
                            Successful candidates receive offer letters and
                            guidance on joining formalities.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="600"
                        data-aos-once="true"
                      >
                        <div className="single-academics-card">
                          <div className="academic-top-content text-center">
                            <img
                              src="assets/images/curriculum/curriculum4.png"
                              width="50px"
                              height="50px"
                              title="Leading Recruiters for Architecture Students"
                              alt="Leading Recruiters for Architecture Students"
                            />
                            <h3>Personal Interviews</h3>
                          </div>
                          <p>
                            Final interviews are conducted by the recruiting
                            companies.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Academic Area --> */}
                {/* <!-- Start Academic Area --> */}
                <div className="academic-area pb-71">
                  <div className="container">
                    <div className="text-center">
                      <h2 className="text-start">Our Recruiters</h2>
                      <p className="text-start">
                        We have a robust network of industry partners and
                        recruiters who regularly visit our campus for
                        placements. Some of our esteemed recruiters include.
                      </p>
                    </div>
                    <div className="row justify-content-left mt-5">
                      {placementsList.length > 0 ? (
                        placementsList.map((item, index) => (
                          <div
                            className="col-lg-2 col-md-6"
                            data-aos="fade-up"
                            key={index}
                          >
                            <div className="academic-top-content text-center">
                              <img
                                src={item.image}
                                style={{
                                  height: "162px",
                                  width: "162%",
                                }}
                                title="Architecture Colleges with Highest Placements"
                                alt="Architecture Colleges with Highest Placements"
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No placements available at the moment.</p>
                      )}
                    </div>
                  </div>
                </div>
                {/* <!-- End Academic Area --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Academic Details Area--> */}
    </>
  );
};
