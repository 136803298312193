import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listAnnouncement } from "../server/apihelper";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Confetti from "react-confetti";

export const Announcement = () => {
  const [announcementList, setAnnouncementList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 700, height: 450 });

  useEffect(() => {
    AOS.init();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const query = "page=1&items_per_page=10&type=1";
    listAnnouncement(query).then((res) => {
      setAnnouncementList(res.data);
    });
  }, []);

  useEffect(() => {
    setIsModalOpen(announcementList.length > 0);
  }, [announcementList]);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const minHeight = 450; // Reduced minimum height

    setImageSize({
      width: naturalWidth > 700 ? 700 : naturalWidth, // Reduced width
      height: naturalHeight < minHeight ? minHeight : naturalHeight,
    });
  };

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      600: { items: 1 },
      1000: { items: 1 },
    },
  };

  return (
    <>
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          {/* Modal Content */}
          <div
            data-aos="zoom-in"
            style={{
              position: "relative",
              width: imageSize.width,
              height: "auto",
              maxHeight: "90vh", // Ensures it fits on screens
              background: "white",
              color: "#000",
              textAlign: "center",
              borderRadius: "15px",
              padding: "20px",
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            {/* Confetti Effect */}
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              numberOfPieces={200} // Reduced for better performance
              gravity={0.2}
              recycle={true}
              style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
            />

            {/* Close Button */}
            <div
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "20px",
                height: "20px",
                background: "#bb2a2e",
                color: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "10px",
                fontWeight: "bold",
                cursor: "pointer",
                zIndex: 2,
                transition: "0.3s",
              }}
            >
              ✖
            </div>

            {/* Announcements Slider */}
            <div className="lates-news-area">
              <div className="container">
                <div className="section-title">
                  <OwlCarousel className="news-slider owl-theme" {...options}>
                    {announcementList?.map((announcement, index) => (
                      <div className="style2" key={index}>
                        <h1
                          style={{
                            fontSize: "22px",
                            marginBottom: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "30px",
                          }}
                        >
                          {announcement.title}
                        </h1>
                        <div
                          className="news-img"
                          style={{
                            width: "100%",
                            height: imageSize.height,
                            maxHeight: "450px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src={announcement.image}
                            alt={announcement.title}
                            onLoad={handleImageLoad}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <p style={{ fontSize: "1.1rem", marginTop: "15px" }}>
                          <b>{announcement.description}</b>
                        </p>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
