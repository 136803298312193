import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listStaff } from "../server/apihelper";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

export const Staff = () => {
  const [staffList, setStaffList] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const query = "page=1&items_per_page=12";
    listStaff(query).then((res) => {
      console.log("res", res);

      setStaffList(res.data);
      console.log("res.data", res.data);
    });
  }, []);
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Faculty & Staff at McGAN'S Ooty School of Architecture | Best
            Architecture Professors in Tamil Nadu
          </title>
          <meta
            name="description"
            content="Explore the faculty and staff at McGAN'S Ooty School of Architecture, featuring top architecture professors in Tamil Nadu. Empowering students with expert guidance and innovative teaching methods."
          />
          <meta
            name="keywords"
            content="Faculty at McGAN'S Ooty, 
Architecture staff Tamil Nadu, 
Best architecture professors in India, 
McGAN'S architecture faculty, 
Experienced architecture professors, 
Ooty architecture school faculty"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/staff" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Architecture Faculty & Staff at McGAN'S Ooty School of Architecture
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Staff</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">Staff</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Health Care Area--> */}
        <div class="health-care-area pt-100 pb-70">
          <div class="container">
            <div class="row justify-content-left">
              <h2 className="CarrersDetails1">
                Empowering Our Staff for Success
              </h2>
              <p>
                We believe that our greatest asset is our people. Our staff is
                empowered with the tools, training, and support they need to
                thrive. Through continuous learning and growth opportunities, we
                ensure that every team member can excel in their role and
                contribute to the collective success of our organization.
                Together, we achieve remarkable results and foster a culture of
                innovation and excellence.
              </p>
              {staffList.map((item, index) => (
                <div className="col-lg-3 col-md-6 mt-5" key={index}>
                  <div className="single-health-care-card">
                    <div className="img">
                      <a>
                        <img
                          className="staffImage"
                          src={item.profile}
                          style={{
                            width: "261px",
                            height: "300px",
                          }}
                          title="Faculty at McGAN'S Ooty"
                          alt="Faculty at McGAN'S Ooty"
                        />
                      </a>
                    </div>
                    <div className="health-care-content">
                      <a>
                        <h5 className="staffstyle">{item.name}</h5>
                      </a>
                      <h6 className="staffstyle">{item.designition}</h6>
                      <Link
                        to={`/staff-details/${item?.primaryId}`}
                        className="read-more-btn"
                      >
                        <span className="ReadMoreStyle staffstyle1">
                          View Profile
                        </span>
                        <i className="flaticon-next"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <!--End Health Care Area--> */}
      </div>
    </>
  );
};
