import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL;
const razorPayScriptUrl = "https://checkout.razorpay.com/v1/checkout.js";
const logo = process.env.REACT_APP_API_IMAGE + "/logo/cmalogoo.webp";

function PayamentCard() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [currentDate] = useState(new Date());

  const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  };

  // const getUserPlanInfo = async () => {
  //   console.log("apiUrl11", apiUrl);
  //   var detail = await axios.get(
  //     `${apiUrl}/getUserDetailForPayment/${postplanId}/${postuserId}`
  //   );
  //   setUserInfo(detail?.data?.modifiedData);
  // };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const getRazorPayOrder = async () => {
    console.log("apiUrl", apiUrl);
    // Create a new order
    const orderResponse = await axios.get(`${apiUrl}/razarpay/`);
    return orderResponse.data;
  };

  const loadRazorOptions = (razorpayOrder) => {
    //debugger;
    let amount = 10;
    const options = {
      key: razorpayOrder?.razorpayKey,
      amount: amount.toString(),
      currency: razorpayOrder?.currency,
      name: "Mcgan's",
      description: "Application Fees",
      image: logo,
      order_id: razorpayOrder?.id,
      handler: async function (response) {
        // Check if the response is valid
        if (response) {
          // Check if payment is successful
          if (response.razorpay_payment_id) {
            console.log("Payment successful:", response);
            // Redirect to the success page
            navigate("/success");
          } else {
            // Payment failed or was cancelled
            console.error("Payment was not successful or cancelled:", response);
            navigate("/failure");
          }
        } else {
          // If the response is invalid or null, redirect to failure
          console.error("No response received from Razorpay.");
          navigate("/failure");
        }
      },

      notes: {
        address: "Mcgan's",
      },
      theme: {
        color: "#ff846f",
      },
      // prefill with user data
      prefill: {
        name: razorpayOrder.name,
        email: razorpayOrder.email,
        contact: razorpayOrder.mobile,
      },
      // ... other options ...
    };
    return options;
  };

  const displayRazorpay = async () => {
    try {
      // Load the Razorpay script
      const scriptLoaded = await loadScript(razorPayScriptUrl);
      if (!scriptLoaded) {
        throw new Error(
          "Razorpay SDK failed to load. Please check your internet connection."
        );
      }

      const razorpayOrder = await getRazorPayOrder();

      // Fetch user profile
      const userProfileResponse = await axios.get(
        `${apiUrl}/viewUserProfile/${razorpayOrder?.userId}`
      );
      const userInfo = userProfileResponse.data.data[0] || {};

      // Configure Razorpay options
      // debugger;
      // Initialize and open the payment dialog
      const paymentObject = new window.Razorpay(
        loadRazorOptions(razorpayOrder)
      );
      paymentObject.open();

      // Handle payment success
      paymentObject.on("payment.success", async function (response) {
        console.log("payment.succ", response);
        console.log("payment.success");
      });

      // Handle payment error
      paymentObject.on("payment.error", async function (response) {
        // Handle error and possibly send a push notification
        console.log("payment.error", response);
      });
    } catch (error) {
      // Handle any errors here
      console.error("Payment error:", error);
      // Display an error message to the user
      alert("Payment error. Please try again later.");
    }
  };

  const handleModalSubmit = async () => {
    try {
      // setShowModal(false);
      displayRazorpay();
    } catch (error) {
      console.error("Modal submission error:", error);
    }
  };

  const handleClose = async () => {
    try {
      setShowModal(false);
      navigate("/cancel");
    } catch (error) {
      console.error("Modal submission error:", error);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Welcome to Mcgan's</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3>Payment Confirmation</h3>
          <h6 style={{ color: "#ff846f" }}>User Details</h6>
          <p> Name : {userInfo?.userInfo?.name}</p>
          <p> Mobile Number : {userInfo?.userInfo?.mobile}</p>
          <p>
            {" "}
            {userInfo?.userInfo?.email
              ? `Email : ${userInfo?.userInfo?.email}`
              : ""}
          </p>

          <h6 style={{ color: "#ff846f" }}>Upgrade Plan Details</h6>
          <span>
            <p>
              {" "}
              Plan : <b>{userInfo?.upgardePlan?.name}</b>
            </p>
            <p>
              {" "}
              Plan Price :{" "}
              <b>
                {" "}
                {userInfo?.upgardePlan?.amount} / {userInfo?.upgardePlan?.days}{" "}
                Day
              </b>
            </p>

            <p>
              {" "}
              Plan Expiry :{" "}
              <b>
                {addDays(
                  currentDate,
                  userInfo?.upgardePlan?.days
                ).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </b>
            </p>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Now
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PayamentCard;
