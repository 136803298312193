import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useCookies } from "react-cookie";

const CookieConsentComponent = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["userConsent"]);
  const [showSettings, setShowSettings] = useState(false);

  // Function to handle accepting cookies
  const handleAccept = () => {
    setCookie("userConsent", true, { path: "/" });
    setShowSettings(false);
  };

  // Function to handle declining cookies
  const handleDecline = () => {
    removeCookie("userConsent", { path: "/" });
    setShowSettings(false);
  };

  // Function to open cookie settings
  const handleSettings = () => {
    setShowSettings(true);
  };

  return (
    <>
      <CookieConsent
        buttonText="I accept"
        declineButtonText="Decline"
        onAccept={handleAccept}
        onDecline={handleDecline}
        cookieName="userConsent"
        style={{ background: "#2B373B", color: "white" }}
        buttonStyle={{
          backgroundColor: "#4CAF50",
          color: "white",
          fontSize: "13px",
        }}
        declineButtonStyle={{
          backgroundColor: "#f44336",
          color: "white",
          fontSize: "13px",
        }}
      >
        We use cookies to enhance your experience.{" "}
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handleSettings}
        >
          Cookie Settings
        </span>
      </CookieConsent>

      {/* Cookie Settings Modal */}
      {showSettings && (
        <div
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "20px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <h3>Cookie Settings</h3>
          <p>
            Manage your cookie preferences. You can change your settings at any
            time.
          </p>
          <button
            onClick={handleAccept}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "10px 20px",
              margin: "10px",
            }}
          >
            Accept All Cookies
          </button>
          <button
            onClick={handleDecline}
            style={{
              backgroundColor: "#f44336",
              color: "white",
              padding: "10px 20px",
              margin: "10px",
            }}
          >
            Decline All Cookies
          </button>
          <button
            onClick={() => setShowSettings(false)}
            style={{
              backgroundColor: "#ccc",
              padding: "10px 20px",
              margin: "10px",
            }}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default CookieConsentComponent;
