import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listCareers } from "../server/apihelper";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Carrers = () => {
  const [careersList, setCareersList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const query = "page=1&items_per_page=10";
    listCareers(query).then((res) => {
      setCareersList(res.data);
    });
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Careers at McGAN'S Ooty School of Architecture | Job Opportunities
            in Architecture Education
          </title>
          <meta
            name="description"
            content="Explore exciting career opportunities at McGAN'S Ooty School of Architecture. Join our team of professionals in shaping the future of architecture education and student development in Tamil Nadu."
          />
          <meta
            name="keywords"
            content="Careers at McGAN'S Ooty, 
Architecture job openings, 
Architecture faculty jobs Tamil Nadu, 
Ooty architecture school careers, 
Teaching jobs in architecture, 
Architecture education careers, 
McGAN'S Ooty job opportunities"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/carrers" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          "Career Opportunities at McGAN'S Ooty School of Architecture "
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Careers</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">Careers</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* Start Campus Information */}
        <div class="campus-information-area pb-70">
          <div class="container">
            <div class="row align-items-left mt-5">
              <h2 className="CarrersDetails1">Welcome to Careers at Mcgan's</h2>
              <p>
                Design and improve curriculum that fosters student growth.
                Collaborate with educators to create engaging learning
                materials. Shape how students learn and apply knowledge in
                classrooms.
              </p>
              <div className="success-stories-container">
                {/* Map through the careersList and create a story card for each job */}
                {careersList.map((career) => (
                  <div className="story-card row" key={career.id}>
                    <h4>{career.job_title}</h4>
                    {/* Adjust according to your API response */}
                    <hr className="divider" />
                    <div className="student-details col-lg-3 col-sm-6">
                      <h4>Job Title</h4>
                      <span>{career.job_title}</span>
                      {/* Replace with actual job title */}
                    </div>
                    <div className="student-details col-lg-3 col-sm-6">
                      <h4>Qualification</h4>
                      <span>{career.qualification}</span>
                      {/* Replace with actual qualification */}
                    </div>
                    <div className="student-details col-lg-3 col-sm-6">
                      <h4>Experience</h4>
                      <span>{career.experience_max}</span>
                      {/* Replace with actual experience */}
                    </div>
                    <div className="student-details col-lg-3 col-sm-6 mt-3">
                      <h4>Vacancy</h4>
                      <span>{career.no_vacancy}</span>
                      {/* Replace with actual vacancy */}
                    </div>
                    <div className="student-details col-lg-3 col-sm-6 mt-3">
                      <h4>Place</h4>
                      <span>{career.place}</span>{" "}
                      {/* Replace with actual place */}
                    </div>
                    <div className="student-details col-lg-3 col-sm-6 mt-3">
                      <button
                        className="btn carrer-button"
                        onClick={() =>
                          navigate(`/carrers-details/${career.primaryId}`)
                        }
                      >
                        Explore <i className="flaticon-next carrersarrow"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* End Campus Information */}
      </div>
    </>
  );
};
