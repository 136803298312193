import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { getContactEmail } from "../server/apihelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    subject: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getContactEmail(formData);
      if (response.message === "Message sent successfully!") {
        toast.success("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone_no: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again.");
    }
  };
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Get in Touch with McGAN'S Ooty | Leading Architecture Institute in
            Tamil Nadu
          </title>
          <meta
            name="description"
            content="Connect with McGAN'S Ooty School of Architecture for inquiries about admissions, programs, and events. Located in the heart of Tamil Nadu, we’re shaping future architects with excellence."
          />
          <meta
            name="keywords"
            content="Contact Leading Architecture Institute Tamil Nadu, 
McGAN'S Ooty Contact Details, 
Top Architecture School Tamil Nadu, 
Admissions Inquiry Architecture College, 
Connect with Architecture Institute Tamil Nadu, 
McGAN'S Address Ooty"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/contact" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          "Contact McGAN'S Ooty School of Architecture "
        </h1>
      </div>
      <div class="page-banner-area bg-2">
        <div class="container">
          <div class="page-banner-content">
            <h3 className="BannerHeader">Contact Us</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Contact Us</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--End Page Banner--> */}

      {/* <!--Start Contact Us Area--> */}
      <div class="contact-us-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contacts-form">
                <h3>Leave a message</h3>
                <form onSubmit={handleSubmit} id="contactForm">
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <label>Your name</label>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          class="form-control"
                          required
                          data-error="Please Enter Name"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <label>Your email</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          class="form-control"
                          required
                          data-error="Please Enter Email"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <label>Your phone</label>
                        <input
                          type="text"
                          name="phone_no"
                          value={formData.phone_no}
                          onChange={handleInputChange}
                          required
                          data-error="Please Enter Number"
                          class="form-control"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <label>Subject</label>
                        <input
                          type="text"
                          name="subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                          class="form-control"
                          required
                          data-error="Please Enter Subject"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label>Your message</label>
                        <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          class="form-control"
                          cols="20"
                          rows="6"
                          required
                          data-error="Write Message"
                        ></textarea>
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="form-check">
                        <input
                          name="gridCheck"
                          value="I agree to the terms and privacy policy."
                          class="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                          required
                        />
                        <label class="form-check-label" for="gridCheck">
                          I agree to the{" "}
                          <Link to="/terms-conditions">terms</Link> and{" "}
                          <Link to="/privacy-policy">privacy policy</Link>
                        </label>
                        <div class="help-block with-errors gridCheck-error"></div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <button type="submit" class="default-btn">
                        <span>Send message</span>
                      </button>
                      <div id="msgSubmit" class="h3 text-center hidden"></div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </form>
                {successMessage && (
                  <div className="alert alert-success">{successMessage}</div>
                )}
                {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-and-address">
                <h2>Let's Contact Us</h2>
                <p>
                  Connect with McGAN'S Ooty School of Architecture for inquiries
                  and opportunities—let’s start a conversation today!
                </p>
                <div class="contact-and-address-content">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="contact-card">
                        <div class="icon">
                          <i class="ri-phone-line"></i>
                        </div>
                        <h4>Contact</h4>
                        <p>
                          Mobile : <a href="tel:+8819906886">+91 79042 57993</a>
                        </p>
                        {/* <p>
                          <a href="tel:9344511168">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91
                            - 93445 11168
                          </a>
                        </p>
                        <p>
                          <a href="tel:04232225970">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0423
                            - 2225970
                          </a>
                        </p> */}
                        <p className="EmailStyle">
                          Mail :
                          <a href="mailto:mcgansoffice@gmail.com">
                            {""} mcgansoffice@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="contact-card">
                        <div class="icon">
                          <i class="ri-map-pin-line"></i>
                        </div>
                        <h4>Address</h4>
                        <p>
                          Kotagiri Road, Ooty, The Nilgiris, Tamilnadu - 643002
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="social-media">
                  <h3>Social Media</h3>
                  <p>
                    Follow us on social media by clicking the icons below for
                    the latest updates and insights!
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/archmcgan-ooty-2b1897304?utm_source=share&amp;utm_campaign=share_via&amp;utm_content=profile&amp;utm_medium=android_app"
                        target="_blank"
                      >
                        <i
                          className="ri-linkedin-fill"
                          // style={{ color: "#e32845" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/mcgansarch?t=2DUahM0qDkcR8Q3HJFR94g&s=08"
                        target="_blank"
                      >
                        <i class="ri-twitter-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@mcgansooty3246"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-youtube-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/?lang=en" target="_blank">
                        <i className="ri-instagram-line"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com" target="_blank">
                        <i className="ri-facebook-fill"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Contact Us Area--> */}

      <iframe
        style={{ border: "0" }} // Change this line
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.8681261624297!2d76.76506041475486!3d11.417116491888672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8be3a914e4353%3A0x2dedb528b948c1e6!2sMcGan's%20Ooty%20School%20of%20Architecture!5e0!3m2!1sen!2sin!4v1577037601094!5m2!1sen!2sin"
        frameBorder="0"
        allowFullScreen
        width="100%"
        height="400"
      />
      <ToastContainer />
    </>
  );
};
