import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            About Us | Best Architecture College in Tamil Nadu | Top B.Arch
            Institute in Ooty and Coimbatore
          </title>
          <meta
            name="description"
            content="Join McGAN’S Ooty School of Architecture, one of the top architecture colleges in Tamil Nadu. Offering the best B.Arch courses under Anna University, our institute is recognized by the Council of Architecture. Study in Ooty, Coimbatore, or the Nilgiris with modern facilities, NATA coaching, and innovative programs. Discover your potential at a leading architecture college in South India."
          />
          <meta
            name="keywords"
            content="Best architecture colleges in Tamil Nadu,
Top architecture colleges in India,
Architecture courses under Anna University,
B.Arch colleges in Tamil Nadu,
Architecture colleges in Coimbatore,
Architecture institutes in Ooty,
Top 10 architecture colleges in Tamil Nadu,
Best B.Arch colleges near Ooty,
Council of Architecture recognized colleges,
Colleges offering NATA coaching in Tamil Nadu,
Top-rated architecture programs in Tamil Nadu,
Affordable architecture colleges in South India,
Best design colleges in Tamil Nadu,
Architecture courses in the Nilgiris,
Colleges for architecture in Tamil Nadu,
Best colleges for B.Arch in Tamil Nadu,
Architecture colleges affiliated with Anna University,
Architecture institutes in South India,
Architecture entrance exam coaching (NATA, CEPT),
Architecture course details in Tamil Nadu,
Leading architecture colleges near Coimbatore,
Modern architecture colleges in Ooty,
Architecture colleges in hill stations,
Colleges for architecture in the Nilgiris,
Best educational institutions for architecture in India,
Architecture colleges in Ooty,
Top B.Arch colleges in the Nilgiris,
Best design institutes in Ooty,
Architecture education in Ooty hill station,
Colleges near Ooty for architecture courses,
Leading architecture colleges in Tamil Nadu’s hill areas"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/about-us" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          About McGAN’S Ooty School of Architecture – Leading B.Arch College in
          Tamil Nadu and Coimbatore
        </h1>
      </div>
      <div>
        {/* Page Banner Section */}
        <div className="page-banner-area bg-2">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">About Us</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">About Us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* Start Section 1 */}
        <div className="campus-information-area">
          <div className="container">
            <div className="row align-items-center mt-5">
              <div
                className="col-lg-6"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1300"
                data-aos-once="true"
              >
                <div className="campus-content pr-20">
                  <div className="campus-title">
                    <h2>About Us</h2>
                    <p className="text-justify">
                      At McGAN’S Ooty School of Architecture, we foster an
                      enriching environment where students engage in off-campus
                      research, independent study, and internships, enhancing
                      their professional readiness. Our mission is to nurture
                      design-minded leaders who excel in critical thinking,
                      ethical behavior, and professional practice. Surrounded by
                      South India's breathtaking landscape, our curriculum
                      emphasizes interdisciplinary knowledge, integrating arts,
                      engineering, sociology, and more. We prioritize
                      experiential learning and problem-solving, ensuring our
                      students are well-prepared for the challenges of the
                      profession. Beyond academics, we cultivate a community
                      committed to architectural excellence, both locally and
                      globally. Our approach transforms graduates into socially
                      responsible individuals, dedicated to making a positive
                      impact in society.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1300"
                data-aos-once="true"
              >
                <div className="campus-image  pl-20">
                  <img
                    src="assets/images/about/about.jpg"
                    style={{ width: "526px", height: "378px" }}
                    title="About"
                    alt="about"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Section 1 */}
        {/* <!--Start Campus Information--> */}
        <div className="campus-information-area pb-50">
          <div className="container">
            <div className="row align-items-stretch mt-5">
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1300"
                data-aos-once="true"
              >
                <div className="campus-content pr-20 d-flex flex-column">
                  <div className="campus-title">
                    <div className="academic-top-content">
                      <h3>
                        <img
                          src="assets/images/about/target.png"
                          style={{ width: "32px", height: "32px" }}
                          title="Mission"
                          alt="Mission"
                        />{" "}
                        Mission
                      </h3>
                    </div>
                  </div>
                  <p className="text-justify flex-grow-1">
                    All of our students are encouraged to continue their
                    research off campus and independent study as well as
                    internships, which jump start your resume and provide
                    valuable job experience. The faculty and student community
                    at McGAN’S is dedicated to working towards a common goal,
                    the betterment of architectural education and practice, not
                    only in India but also in the global context. The mission of
                    McGAN’S Ooty School of Architecture is to nurture and
                    inspire design-minded students. Our students demonstrate a
                    firm foundation of critical thinking, ethical behaviour and
                    a culture of professional practice, on their way to becoming
                    socially responsible leaders of change for our global
                    society. McGAN’S achieves this mission through: A
                    progressive curriculum, taught by accomplished, active
                    faculties. Practitioner-based models of learning focused on
                    problem-solving, experiential learning and process-based
                    design. Active participation and engagement in our local and
                    global community. The constant pursuit of academic
                    excellence.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1300"
                data-aos-once="true"
              >
                <div className="campus-content pr-20 d-flex flex-column">
                  <div className="campus-title">
                    <div className="academic-top-content">
                      <h3>
                        <img
                          src="assets/images/about/eye.png"
                          style={{ width: "32px", height: "32px" }}
                          title="Vision"
                          alt="Vision"
                        />{" "}
                        Vision
                      </h3>
                    </div>
                  </div>
                  <p className="text-justify flex-grow-1">
                    McGAN’S Ooty School of Architecture is committed to create a
                    world class learning centre for architecture in India. We
                    are blessed to be sitting amidst one of the most scenic
                    landscape in South India which naturally makes us more
                    sensitive to environment. At McGAN’S we understand that
                    architecture requires knowledge of many disciplines like
                    arts, engineering, model making, sociology, anthropology and
                    other allied disciplines. The teaching methodology at
                    McGAN’S is not only restricted to classrooms but goes beyond
                    the four walls. Our institution not only aims at creating
                    mere graduates but also preparing our students for numerous
                    challenges in the profession, also turning them to much
                    better human beings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--End Campus Information--> */}
        {/* <!--Start --> */}
        <div className="eduction-area pb-70">
          <div className="container-fluid p-0 mt-3">
            <div className="section-title">
              <h3>Start Your Journey for McGAN’S</h3>
              <p>
                State-of-the-Art Facilities Access top-notch facilities and
                resources for an enhanced learning experience
              </p>
            </div>
            <div className="row g-0 aboutstyle">
              <div
                className="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="single-education-card1 bg-1">
                  <div className="edication-content">
                    <div className="icon AboutStyle">
                      {/* <i className="flaticon-college"></i> */}
                      <img
                        src="assets/images/about/icon1.png"
                        style={{ width: "73px", height: "73px" }}
                        title="icon1"
                        alt="icon1"
                      />
                    </div>
                    <h3 className="aboutDetails1">Our College</h3>
                    <p>
                      <h4 className="aboutDetails">
                        "Empowering minds, inspiring futures. Excellence in
                        education and beyond!"
                      </h4>
                    </p>
                    <p className="read-more-btn white-color"></p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="400"
                data-aos-once="true"
              >
                <div className="single-education-card1 bg-2">
                  <div className="edication-content">
                    <div className="icon AboutStyle">
                      {/* <i className="flaticon-student-with-graduation-cap"></i> */}
                      <img
                        src="assets/images/about/icon2.png"
                        style={{ width: "73px", height: "73px" }}
                        title="icon2"
                        alt="icon2"
                      />
                    </div>
                    <h3 className="aboutDetails1">Admission</h3>
                    <p>
                      <h4 className="aboutDetails">
                        "Unlock your potential. Join us for a transformative
                        education journey!"
                      </h4>
                    </p>
                    <p className="read-more-btn white-color"></p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="600"
                data-aos-once="true"
              >
                <div className="single-education-card1 bg-3">
                  <div className="edication-content">
                    <div className="icon AboutStyle">
                      {/* <i className="flaticon-college-graduation"></i> */}
                      <img
                        src="assets/images/about/icon3.png"
                        style={{ width: "73px", height: "73px" }}
                        title="icon3"
                        alt="icon3"
                      />
                    </div>
                    <h3 className="aboutDetails1">Scholarships</h3>
                    <p>
                      <h4 className="aboutDetails">
                        "Achieve more with our scholarships. Supporting your
                        path to success!"
                      </h4>
                    </p>
                    <p className="read-more-btn white-color"></p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="600"
                data-aos-once="true"
              >
                <div className="single-education-card1 bg-4">
                  <div className="edication-content">
                    <div className="icon AboutStyle">
                      {/* <i className="flaticon-college-graduation"></i> */}
                      <img
                        src="assets/images/about/icon4.png"
                        style={{ width: "73px", height: "73px" }}
                        title="icon4"
                        alt="icon4"
                      />
                    </div>
                    <h3 className="aboutDetails1">Tour</h3>
                    <p>
                      <h4 className="aboutDetails">
                        "Experience campus life firsthand. Join us for an
                        inspiring college tour!"
                      </h4>
                    </p>
                    <p className="read-more-btn white-color"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Education Area--> */}

        {/* Start Campus Information - Vision Section */}
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6  col-md-6">
              <div className="admission-left-content">
                <h2>Sustainability</h2>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Sustainability principles in all programs.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i> Focus on
                  renewable energy and conservation.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Recycling and energy conservation efforts.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Partnering with local organizations for awareness.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Addressing sustainability challenges together.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-5">
              <div className="admission-left-content ">
                <h2></h2>

                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Energy-efficient and eco-friendly building designs.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i> Active
                  participation in sustainability initiatives.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Educating about eco-friendly practices.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                  Understanding worldwide sustainability issues.
                </p>
                <p className="contentAlign">
                  <i className="ri-checkbox-circle-line iconStyle"></i> Engaging
                  alumni in community sustainability efforts.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Admisssion Area--> */}

        {/* <!--Start Campus Information--> */}
        <div className="academic-area pt-100 pb-70">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="600"
                data-aos-once="true"
              >
                <div className="single-academics-card bg-faculty">
                  <div className="academic-top-content">
                    <h3>
                      <img
                        src="assets/images/about/faculty.png"
                        style={{ width: "32px", height: "32px" }}
                        title="faculty"
                        alt="faculty"
                      />{" "}
                      Faculty Practitioners
                    </h3>
                  </div>
                  <p className="text-justify">
                    At McGAN’S, our faculty comprises highly experienced
                    practitioners who infuse real-world insights into the
                    classNameroom. This approach ensures that students are
                    well-prepared to navigate the evolving landscape of
                    architecture and design.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="800"
                data-aos-once="true"
              >
                <div className="single-academics-card bg-placement">
                  <div className="academic-top-content">
                    <h3>
                      {" "}
                      <img
                        src="assets/images/about/placement.png"
                        style={{ width: "32px", height: "32px" }}
                        title="placement"
                        alt="placement"
                      />{" "}
                      Placement Drive 2023
                    </h3>
                  </div>
                  <p className="text-justify">
                    The upcoming placement drive starting November 27 offers
                    exceptional opportunities for candidates to connect with
                    industry leaders. We encourage all students to actively
                    participate and leverage this invaluable initiative for
                    their career growth.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="800"
                data-aos-once="true"
              >
                <div className="single-academics-card bg-placement">
                  <div className="academic-top-content">
                    <h3>
                      {" "}
                      <img
                        src="assets/images/about/philosphy.png"
                        style={{ width: "32px", height: "32px" }}
                        title="philosphy"
                        alt="philosphy"
                      />{" "}
                      Our Philosophy
                    </h3>
                  </div>
                  <p className="text-justify">
                    At McGAN’S, we set high standards that challenge students
                    while nurturing their artistic passion and professional
                    excellence. We believe architecture and design can offer
                    fulfillment to a diverse range of individuals, making it an
                    accessible and rewarding field of study.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Campus Information--> */}
      </div>
    </>
  );
};
