import React from "react";
import "./WelcomeCard.css"; // Custom styles

const WelcomeCard = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card welcome-card text-center shadow-lg p-4">
        <div className="card-body">
          <h2 className="card-title mb-4">Welcome to Mcgan's </h2>
          <p className="card-text">
            Welcome to a journey of growth, discovery, and endless
            possibilities!
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
