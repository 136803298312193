import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const MyBannerVideo = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="banner-area banner-area-five">
        <video controls autoPlay muted loop style={{ width: "100%" }}>
          <source src="assets/images/Video.mov" type="video/mp4" />
          <source src="assets/images/Video.mov" type="video/ogg" />
          Your browser does not support HTML video.
        </video>
        <div>
          <div className="slider-item">
            <div className="container-fluid">
              <div className="slider-content slidercss">
                <h3 className="Owlcarousel">
                  Architectural Beauty That Transcends Time...
                </h3>
                <p>
                  Timeless Architecture That Merges Creativity With Precision,
                  Shaping Spaces That Inspire.
                </p>
                <Link to="/about-us" className="default-btn btn">
                  About Us <i className="flaticon-next"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBannerVideo;
