import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const CampusStudioDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Leading Architecture Design Studio for Students in Tamil Nadu |
            McGAN'S Ooty School of Architecture Lab
          </title>
          <meta
            name="description"
            content="McGAN'S Ooty School of Architecture’s design studio in Tamil Nadu offers students the opportunity to engage in innovative projects, sustainable architecture, and cutting-edge design techniques. A space for creative exploration and architectural growth."
          />
          <meta
            name="keywords"
            content="architecture design studio Tamil Nadu, architecture lab Tamil Nadu, best architecture studios for students Tamil Nadu, innovative design studio Tamil Nadu, sustainable architecture Tamil Nadu, architecture student design studio Tamil Nadu, creative architecture studio Tamil Nadu, cutting-edge architecture Tamil Nadu, architecture student lab Tamil Nadu, architectural research studio Tamil Nadu, advanced design techniques Tamil Nadu, virtual reality architecture Tamil Nadu, 3D modeling design Tamil Nadu, sustainable architecture projects Tamil Nadu, collaborative architecture studio Tamil Nadu, architecture innovation Tamil Nadu, architecture education Tamil Nadu, architecture studio Ooty, architecture research and development Tamil Nadu, student design space Tamil Nadu, design studio for architecture students Tamil Nadu."
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/campus-studiodetails"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Top Architecture Design Studio in Tamil Nadu for Students at McGAN'S
          Ooty School of Architecture
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus Studio Details</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/campus">Campus</Link>
                </li>
                <li className="navspace">Studio Details</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Academic Details Area--> */}
        <div className="academics-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="academics-details">
                  <div className="details">
                    <h2>Innovating Spaces at the Architecture Studio</h2>
                    <p>
                      The architecture lab is where creativity meets
                      functionality. It's a place where visionary designs are
                      brought to life, blending aesthetics with practicality to
                      create transformative spaces.
                    </p>

                    <div className="details-images">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/studio1.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="architecture design studio Tamil Nadu"
                            alt="architecture design studio Tamil Nadu"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/studio2.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="architecture lab Tamil Nadu"
                            alt="architecture lab Tamil Nadu"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/studio3.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="best architecture studios for students Tamil Nadu"
                            alt="best architecture studios for students Tamil Nadu"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prepare-exam">
                    <h3>Redefining Architecture Through Innovation</h3>
                    <p>
                      At our architecture lab, we push the boundaries of design
                      and functionality. Every project is an opportunity to
                      explore new concepts, technologies, and ideas that
                      redefine how we experience space.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Cutting-Edge Design Techniques</h4>
                          <p>
                            At the lab, we embrace the latest design
                            technologies, from 3D modeling to virtual reality,
                            to visualize and develop innovative architectural
                            solutions.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Sustainable Architecture</h4>
                          <p>
                            Sustainability is at the core of our designs. We
                            focus on eco-friendly materials, energy-efficient
                            systems, and sustainable building practices to
                            reduce environmental impact.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Collaborative Design Process</h4>
                          <p>
                            Our studio fosters a collaborative environment where
                            architects, engineers, and clients work together to
                            ensure that each project reflects the shared vision
                            of all stakeholders.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Research and Development in Architecture</h4>
                          <p>
                            We continually push the boundaries of architecture
                            through research, testing new materials, techniques,
                            and methods to advance the field and create
                            groundbreaking designs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Academic Details Area--> */}
      </div>
    </>
  );
};
