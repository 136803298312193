import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listGallery } from "../server/apihelper";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

export const Gallery = () => {
  const [galleryList, setGalleryList] = useState([]);
  const [category, setCategory] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [contentType, setContentType] = useState("");
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const query = `page=1&items_per_page=15&category=${category}`;
    listGallery(query).then((res) => {
      setGalleryList(res.data);
    });
  }, [category]);

  const handleContentClick = (content, type, name) => {
    setSelectedContent(content);
    setContentType(type);
    setImageName(name); // Set the image name when clicked
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContent(null);
    setContentType("");
    setImageName(""); // Reset the image name
  };

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Architecture College Gallery | Campus Life, Facilities & Student
            Events
          </title>
          <meta
            name="description"
            content="Architecture college gallery showcasing campus life, state-of-the-art facilities, inspiring student events, and academic excellence at McGAN’S Ooty School of Architecture in the Nilgiris."
          />
          <meta
            name="keywords"
            content="Architecture college gallery, 
McGAN’S gallery, 
Campus life in Tamil Nadu, 
Architecture college photos, 
Architecture facilities, 
Student events, 
Architecture workshops, 
Nilgiris architecture college images, 
Top architecture colleges gallery"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/gallery" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Gallery of McGAN’S Ooty School of Architecture | Campus Life &
          Facilities
        </h1>
      </div>
      <div>
        {/* Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Gallery</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">Gallery</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Categories */}
        <div className="description descriptionul">
          <div
            className="nav nav-tabs d-flex justify-content-center mt-4 navshadow"
            id="nav-tab"
            role="tablist"
          >
            <button
              className={`nav-link gallerybutton ${
                category === "" ? "active" : ""
              }`}
              onClick={() => setCategory("")}
            >
              All
            </button>
            <button
              className={`nav-link gallerybutton ${
                category === 1 ? "active" : ""
              }`}
              onClick={() => setCategory(1)}
            >
              Annual Day
            </button>
            <button
              className={`nav-link gallerybutton ${
                category === 2 ? "active" : ""
              }`}
              onClick={() => setCategory(2)}
            >
              Sports Day
            </button>
            <button
              className={`nav-link gallerybutton ${
                category === 3 ? "active" : ""
              }`}
              onClick={() => setCategory(3)}
            >
              Culturals
            </button>
            <button
              className={`nav-link gallerybutton ${
                category === 4 ? "active" : ""
              }`}
              onClick={() => setCategory(4)}
            >
              Others
            </button>
          </div>

          {/* Gallery List */}
          <div className="container">
            <div className="row justify-content-left">
              {galleryList.length > 0 ? (
                galleryList.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.image && (
                      <div className="col-lg-4 col-md-6">
                        <div className="single-health-care-card">
                          <div className="img">
                            <img
                              src={item.image}
                              alt={item.title}
                              Title="Architecture college gallery"
                              style={{
                                height: "282px",
                                width: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={
                                () =>
                                  handleContentClick(
                                    item.image,
                                    "image",
                                    item.title
                                  ) // Pass the title of the image
                              }
                            />
                          </div>
                          <div className="img-name">
                            <p className="gallerytitle">{item.title}</p>{" "}
                            {/* Display the image name */}
                          </div>
                        </div>
                      </div>
                    )}
                    {item.video && item.video.trim() !== "" && (
                      <div className="col-lg-4 col-md-6">
                        <div className="single-health-care-card">
                          <div className="img">
                            <video
                              controls
                              style={{
                                height: "282px",
                                width: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              Title="McGAN’S gallery"
                              onClick={(e) => {
                                e.preventDefault();
                                handleContentClick(
                                  item.video,
                                  "video",
                                  item.title
                                ); // Pass the title of the video
                              }}
                            >
                              <source src={item.video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                          <div className="img-name">
                            <p className="gallerytitle">{item.title}</p>{" "}
                            {/* Display the video name */}
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <div className="col-12 text-center mt-4">
                  <p>No data available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>{imageName}</h5>
            {/* {contentType === "image" ? "Image Preview" : "Video Preview"} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h5>{imageName}</h5>{" "} */}
          {/* Display the image or video name in the modal */}
          {contentType === "image" && (
            <img
              src={selectedContent}
              alt="Selected"
              style={{
                height: "282px",
                width: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          )}
          {contentType === "video" && (
            <video
              controls
              style={{
                height: "282px",
                width: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
            >
              <source src={selectedContent} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
