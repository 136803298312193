import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Postgraduate = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Master of Architecture (M.Arch) in Environmental Architecture | Top
            M.Arch Colleges in Tamil Nadu
          </title>
          <meta
            name="description"
            content="M.Arch in Environmental Architecture at McGAN’s Ooty School of Architecture offers a unique program focused on sustainable and green architecture. Affiliated with Anna University, this top M.Arch college in Tamil Nadu provides specialized training in low-impact buildings. Located in the scenic Nilgiris, McGAN’s is one of the best M.Arch colleges in South India, offering a curriculum designed to inspire future environmental architects."
          />
          <meta
            name="keywords"
            content="Best architecture colleges in Tamil Nadu,
Top architecture colleges in India,
Architecture courses under Anna University,
B.Arch colleges in Tamil Nadu,
Architecture colleges in Coimbatore,
Architecture institutes in Ooty,
Top 10 architecture colleges in Tamil Nadu,
Best B.Arch colleges near Ooty,
Council of Architecture recognized colleges,
Colleges offering NATA coaching in Tamil Nadu,
Top-rated architecture programs in Tamil Nadu,
Affordable architecture colleges in South India,
Best design colleges in Tamil Nadu,
Architecture courses in the Nilgiris,
Colleges for architecture in Tamil Nadu,
Best colleges for B.Arch in Tamil Nadu,
Architecture colleges affiliated with Anna University,
Architecture institutes in South India,
Architecture entrance exam coaching (NATA, CEPT),
Architecture course details in Tamil Nadu,
Leading architecture colleges near Coimbatore,
Modern architecture colleges in Ooty,
Architecture colleges in hill stations,
Colleges for architecture in the Nilgiris,
Best educational institutions for architecture in India,
Architecture colleges in Ooty,
Top B.Arch colleges in the Nilgiris,
Best design institutes in Ooty,
Architecture education in Ooty hill station,
Colleges near Ooty for architecture courses,
Leading architecture colleges in Tamil Nadu’s hill areas"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/undergraduate" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Master of Architecture (M.Arch) in Environmental Architecture -
          Sustainable Design at McGAN's Ooty School of Architecture
        </h1>
      </div>
      {/* Start Page Banner */}
      <div className="page-banner-area bg-1">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Post Graduate</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Post Graduate</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* <!--Start Academic Details Area--> */}
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="academics-details">
                <div className="details">
                  <h2>Master of Architecture (M.Arch)</h2>
                  <p>
                    Companion to undergraduate in the graduate program is
                    Environmental Architecture, which greatly contributed to the
                    department´s strength. The influence of these programs has
                    created a philosophy that is unique to McGAN’S. This program
                    is approved by Council of Architecture (COA) and the
                    institute is Affiliated to Anna University, Chennai with an
                    intake of 20 Seats.
                  </p>
                  <p>
                    We are the only college offering Environmental Architecture
                    in South India. The syllabus is being made to inspire
                    environmental architects, who are fascinated with or
                    practicing green and low impact environmental impact
                    buildings. The peculiar setting selected by McGAN’S itself
                    inspires the masters to come and learn here. The program is
                    being conceived by the practice architects and passed out
                    graduate students here.
                  </p>
                  <div className="details-images">
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC4.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="AC4"
                          alt="AC4"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC5.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="AC5"
                          alt="AC5"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC6.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="AC6"
                          alt="AC6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="regiStartion-and-fee">
                  <h3>Eligibility</h3>
                  <p>
                    Bachelor of Architecture or equivalent courses recognized by
                    Council of Architecture – New Delhi & Anna University –
                    Chennai.
                  </p>
                  <div class="list">
                    <ul>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>
                        Typically requires a B.Arch or related field degree.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Submission of a design portfolio showcasing previous
                        work.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>
                        2–3 letters of recommendation from academic or
                        professional contacts
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i> A
                        written statement explaining career goals and interest
                        in architecture.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        GRE (for some programs) and TOEFL/IELTS for non-native
                        English speakers.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row regiStartion-and-fee">
                  <div className="col-6">
                    <h3>How to Apply :</h3>
                    <div className="list">
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Review the application requirements carefully.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Gather and prepare all required documents.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Fill out the application form accurately.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Submit your application on time.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Follow up if needed to confirm submission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-6">
                    <h3>Apply Application & Fees</h3>
                    <div className="list">
                      <ul>
                        <button
                          type="button"
                          className="default-btn btn"
                          onClick={() => navigate(`/march-application`)}
                        >
                          Apply Now <i className="flaticon-next"></i>
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Academic Details Area--> */}
    </>
  );
};
