import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const CampusPlaygroundDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Sports & Outdoor Playground for College Students | McGAN'S Ooty
            School of Architecture, Tamil Nadu
          </title>
          <meta
            name="description"
            content="Sports playground for college students at McGAN'S Ooty School of Architecture offers outdoor activities, fitness zones, and recreational spaces to promote physical and mental well-being in Tamil Nadu."
          />
          <meta
            name="keywords"
            content="sports playground for college students, outdoor recreation for students, college sports activities Tamil Nadu, fitness zones for college students, Ooty sports playground, outdoor play spaces Tamil Nadu, recreational activities for college students, fitness and sports playground, college playground Ooty, student fitness areas."
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/campus-playgrounddetails"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Sports Playground for College Students at Our Outdoor Recreation Area
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus Playground</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/campus">Campus</Link>
                </li>
                <li className="navspace">Playground</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Academic Details Area--> */}
        <div className="academics-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="academics-details">
                  <div className="details">
                    <h2>
                      A World of Adventure: Where Creativity, Exploration, and
                      Joy Come Together
                    </h2>
                    <p>
                      Welcome to a vibrant space where imagination knows no
                      bounds! Designed to inspire and delight, this area offers
                      children endless opportunities to explore, play, and
                      connect in a safe and stimulating environment. From
                      interactive zones that spark creativity to activities that
                      promote physical well-being, every corner is crafted to
                      nurture growth, confidence, and joy. Here, kids of all
                      ages can dive into a world that encourages curiosity,
                      fosters friendships, and creates lasting memories.
                    </p>

                    <div className="details-images">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/academic-1.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="sports playground for college students"
                            alt="sports playground for college students"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/academic-2.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="outdoor recreation for students"
                            alt="outdoor recreation for students"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/academic-3.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="college sports activities Tamil Nadu"
                            alt="college sports activities Tamil Nadu"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prepare-exam">
                    <h3>Safety and Accessibility</h3>
                    <p>
                      Designing playgrounds with safety features and accessible
                      pathways ensures that all students, regardless of ability,
                      can engage in activities without risk.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Sports Fields and Courts</h4>
                          <p>
                            Colleges often include fields for sports like
                            football, basketball, and volleyball. These fields
                            provide structured areas for students to play,
                            practice, and compete.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Fitness Zones and Gym Areas</h4>
                          <p>
                            Dedicated fitness zones may include gym equipment,
                            running tracks, or open areas for fitness
                            classNamees, catering to students focused on
                            personal fitness and conditioning.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Recreational Zones and Green Spaces</h4>
                          <p>
                            These areas offer a relaxing environment for
                            students to unwind, featuring benches, walking
                            paths, and spaces for yoga or meditation, helping
                            balance mental and physical health.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Student-Led Clubs and Activities</h4>
                          <p>
                            Playgrounds are also used for student-led clubs or
                            sports teams, offering opportunities to participate
                            in organized sports or group events. This inclusion
                            promotes student involvement in extracurricular
                            activities.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Academic Details Area--> */}
      </div>
    </>
  );
};
