import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Diploma = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Diploma in Architecture Colleges in Tamil Nadu | Top D.Arch Colleges
            in Tamil Nadu | McGAN'S
          </title>
          <meta
            name="description"
            content="Diploma in Architecture colleges in Tamil Nadu offer exceptional opportunities for aspiring architects. McGAN'S Ooty School of Architecture, one of the top D.Arch colleges in Tamil Nadu, provides COA-accredited programs affiliated with Anna University. Located in Ooty’s picturesque Nilgiris, our Environmental Architecture curriculum focuses on sustainability, creativity, and hands-on learning to shape future architects."
          />
          <meta
            name="keywords"
            content="Diploma in Architecture Colleges in Tamil Nadu,
Top Architecture Colleges in Tamil Nadu,
Best Diploma in Architecture Programs,
Architecture Courses in Tamil Nadu,
Diploma in Architecture in Ooty,
COA Accredited Architecture Colleges,
Anna University Architecture Colleges,
Sustainable Architecture Diploma Tamil Nadu,
Environmental Architecture Courses,
Architecture Diploma Admissions Tamil Nadu,
D.Arch Colleges in South India,
Best Architecture Colleges in India,
Top Diploma in Architecture Programs,
Diploma in Architecture Courses Near Me,
Architecture Colleges Tamil Nadu Admissions
"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/diploma" />
        </Helmet>
        <h1 style={{ display: "none" }}>Top D.Arch Colleges in Tamil Nadu</h1>
      </div>
      {/* Page Banner */}
      <div className="page-banner-area bg-1">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Diploma</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Diploma</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* <!--Start Academic Details Area--> */}
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="academics-details">
                <div className="details">
                  <h2>Diploma of Architecture</h2>
                  <p>
                    Companion to undergraduate in the graduate program is
                    Environmental Architecture, which greatly contributed to the
                    department´s strength. The influence of these programs has
                    created a philosophy that is unique to McGAN’S. This program
                    is approved by Council of Architecture (COA) and the
                    institute is Affiliated to Anna University, Chennai with an
                    intake of 20 Seats.
                  </p>
                  <p>
                    We are the only college offering Environmental Architecture
                    in South India. The syllabus is being made to inspire
                    environmental architects, who are fascinated with or
                    practicing green and low impact environmental impact
                    buildings. The peculiar setting selected by McGAN’S itself
                    inspires the masters to come and learn here. The program is
                    being conceived by the practice architects and passed out
                    graduate students here.
                  </p>
                  <div className="details-images">
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC7.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title=" Best Diploma in Architecture Programs"
                          alt=" Best Diploma in Architecture Programs"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC8.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="Diploma in Architecture Colleges in Tamil Nadu"
                          alt="Diploma in Architecture Colleges in Tamil Nadu"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC9.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="Top Architecture Colleges in Tamil Nadu"
                          alt="Top Architecture Colleges in Tamil Nadu"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="regiStartion-and-fee">
                  <h3>Eligibility</h3>
                  <p>
                    Diploma of Architecture or equivalent courses recognized by
                    Council of Architecture – New Delhi & Anna University –
                    Chennai.
                  </p>
                  <div class="list">
                    <ul>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Must have completed high school or equivalent.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Strong background in subjects like math and physics.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Meet specific grade requirements set by the institution.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i> A
                        Some programs require a design portfolio or entrance
                        exam.
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Proof of proficiency if studying in a non-native
                        language.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row regiStartion-and-fee">
                  <div className="col-6">
                    <h3>How to Apply :</h3>
                    <div className="list">
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Review the application requirements carefully.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Gather and prepare all required documents.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Fill out the application form accurately.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Submit your application on time.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Follow up if needed to confirm submission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div className="col-6">
                    <h3>Apply Application & Fees</h3>
                    <div className="list">
                      <ul>
                        <button
                          type="button"
                          className="default-btn btn"
                          onClick={() => navigate(`/diploma-application`)}
                        >
                          Apply Now <i className="flaticon-next"></i>
                        </button>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Academic Details Area--> */}
    </>
  );
};
