import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { createAlumniForm } from "../server/apihelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const Registration = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [employmentType, setEmploymentType] = useState("1");
  const [years, setYears] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState("");
  const [courses, setCourses] = useState([]);
  const [skills, setSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const formRef = useRef(null);

  const step1ValidationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    roll_number: Yup.string().required("Roll Number is required"),
    graduation_year: Yup.string().required("Graduation Year is required"),
    degree: Yup.string().required("Degree is required"),
    course: Yup.string().required("Course is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // mobile_number: Yup.string()
    //   .required("Mobile number is required")
    //   .test("is-valid-phone", "Enter a valid phone number", (value) => {
    //     const phoneRegex = /^\+[1-9]\d{1,14}$/;
    //     return phoneRegex.test(value);
    //   }),
    residence: Yup.string().required("Current City of Residence is required"),
    dob: Yup.date().required("Date of Birth is required"),
    // fb_id: Yup.string()
    //   .url("Invalid Facebook URL")
    //   .required("Facebook ID is required"),
    // linkedin_id: Yup.string()
    //   .url("Invalid LinkedIn URL")
    //   .required("LinkedIn ID is required"),
  });

  const step2ValidationSchema = Yup.object({
    // employmentType: Yup.string().required("Employment type is required"),
    // currentcom: Yup.string().when("employmentType", {
    //   is: (employmentType) => employmentType === "1" || employmentType === "2",
    //   then: Yup.string().required("Current company is required"),
    // }),
    // currentdesign: Yup.string().when("employmentType", {
    //   is: (employmentType) => employmentType === "1" || employmentType === "2",
    //   then: Yup.string().required("Current designation is required"),
    // }),
    // job_domain: Yup.string().required("Job domain is required"),
    // work_year: Yup.string().required("Working year is required"),
    skills: Yup.string().required("Working year is required"),
  });

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [step]);
  // year loaded
  useEffect(() => {
    // Generate a range of years (e.g., from 1900 to the current year)
    const currentYear = new Date().getFullYear();
    const startYear = 1900; // Adjust the starting year as needed
    const yearRange = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearRange.push(year);
    }
    setYears(yearRange);
  }, []);

  //   Emp Type onchange
  const handleEmploymentChange = (event) => {
    setEmploymentType(event.target.value);
  };

  //   Degree & Course
  const degreeOptions = [
    {
      value: "bachelor-architecture",
      label: "Bachelor of Architecture (B.Arch)",
    },
    { value: "master-architecture", label: "Master of Architecture (M.Arch)" },
    { value: "urban-design", label: "Master of Urban Design" },
    {
      value: "landscape-architecture",
      label: "Master of Landscape Architecture",
    },
    { value: "interior-design", label: "Bachelor/Master in Interior Design" },
    {
      value: "construction-management",
      label: "Master in Construction Management",
    },
    { value: "sustainable-design", label: "Master in Sustainable Design" },
    {
      value: "historic-preservation",
      label: "Master in Historic Preservation",
    },
  ];

  const coursesByDegree = {
    "bachelor-architecture": [
      "Building Design",
      "Structural Systems",
      "Environmental Studies",
    ],
    "master-architecture": [
      "Urban Planning",
      "Advanced Design",
      "Sustainability",
    ],
    "urban-design": [
      "Urban Theory",
      "Transportation Planning",
      "Public Spaces",
    ],
    "landscape-architecture": [
      "Plant Studies",
      "Environmental Design",
      "Site Analysis",
    ],
    "interior-design": [
      "Furniture Design",
      "Lighting Design",
      "Space Planning",
    ],
    "construction-management": [
      "Project Management",
      "Construction Law",
      "Cost Estimation",
    ],
    "sustainable-design": [
      "Energy Efficiency",
      "Green Materials",
      "Sustainable Architecture",
    ],
    "historic-preservation": [
      "Restoration Techniques",
      "Cultural Heritage",
      "Conservation Practices",
    ],
  };

  const handleDegreeChange = (event) => {
    const degree = event.target.value;
    setSelectedDegree(degree);
    setCourses(degree ? coursesByDegree[degree] || [] : []);
  };

  //   skills Add
  // const handleAddSkill = () => {
  //   if (currentSkill.trim() && !skills.includes(currentSkill.trim())) {
  //     setSkills([...skills, currentSkill.trim()]);
  //     setCurrentSkill("");
  //   }
  // };
  // const handleRemoveSkill = (skillToRemove) => {
  //   setSkills(skills.filter((skill) => skill !== skillToRemove));
  // };

  // const handleKeyPress = (e) => {
  //   if (e.key === ",") {
  //     // Prevent the comma from appearing in the input field
  //     e.preventDefault();

  //     // Add the skill and clear the input field
  //     handleAddSkill();
  //   }
  // };

  // Form Submit
  const formik = useFormik({
    // initialValues: {
    //   interested_roles: [],
    // },
    initialValues: {
      firstname: "",
      lastname: "",
      roll_number: "",
      graduation_year: "",
      degree: "",
      course: "",
      email: "",
      mobile_number: "",
      country: "",
      residence: "",
      password: "",
      confirm_password: "",
      dob: "",
      gender: "",
      fb_id: "",
      linkedin_id: "",
      emp_type: 0,
      company_name: "",
      current_designation: "",
      job_domain: "",
      working_since: "",
      interested_roles: [],
      skills: "",
    },
    validationSchema:
      step === 1 ? step1ValidationSchema : step2ValidationSchema,
    onSubmit: async (values) => {
      try {
        console.log("Form values:", values);
        const response = await createAlumniForm(values);
        console.log("API response:", response);
        navigate("/alumni");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  // Next button
  const handleNext = () => {
    // Trigger validation before moving to the next step
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
        // window.scrollTo(1, 1);
      } else {
        formik.setTouched(errors);
      }
    });
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  console.log("formik.errors", formik.errors);
  return (
    <>
      <div class="page-banner-area bg-2">
        <div class="container">
          <div class="page-banner-content">
            <h1>Registration For Alumni</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Alumni</li>
              <li className="navspace">Alumni Form</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--End Page Banner--> */}
      {/* <!--Start Register Area--> */}
      <div class="register-area pt-100 pb-70">
        <div class="container">
          <div class="register" ref={formRef}>
            <h3>Alumni Form</h3>
            {/* <h4>Create an account</h4> */}
            <form onSubmit={formik.handleSubmit} className="formstyle">
              {step === 1 && (
                <>
                  <div className="row">
                    {/* Name Field */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="name">First Name</label>
                        <input
                          id="firstname"
                          name="firstname"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.firstname && formik.errors.firstname
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter Your FirstName"
                          {...formik.getFieldProps("firstname")}
                        />
                        {formik.touched.firstname && formik.errors.firstname ? (
                          <div className="invalid-feedback">
                            {formik.errors.firstname}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Last Name Field */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="address">Last Name</label>
                        <input
                          id="lastname"
                          name="lastname"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.lastname && formik.errors.lastname
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter Your LastName"
                          {...formik.getFieldProps("lastname")}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastname && formik.errors.lastname ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block error">
                              {formik.errors.lastname}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* row-2 */}
                  <div className="row">
                    {/* Date of Birth */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="name">Date of Birth</label>
                        <input
                          id="dob"
                          name="dob"
                          type="date"
                          className={`form-control mt-3 ${
                            formik.touched.dob && formik.errors.dob
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter Your Date of Birth"
                          {...formik.getFieldProps("dob")}
                        />
                        {formik.touched.dob && formik.errors.dob ? (
                          <div className="invalid-feedback">
                            {formik.errors.dob}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Gender */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="gender">Gender</label>
                        <div className="dropdown-container">
                          <select
                            id="gender"
                            name="gender"
                            className={`form-control mt-3 ${
                              formik.touched.gender && formik.errors.gender
                                ? "is-invalid"
                                : ""
                            }`}
                            {...formik.getFieldProps("gender")}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <span className="dropdown-icon">
                            <i className="ri-arrow-down-s-line"></i>
                          </span>
                        </div>
                        {formik.touched.gender && formik.errors.gender ? (
                          <div className="invalid-feedback">
                            {formik.errors.gender}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* row-3 */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.email && formik.errors.email
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="example@gmail.com"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="mobile_number" className="mobilenumber">
                          Mobile Number
                        </label>
                        <PhoneInput
                          country={"in"}
                          value={formik.values.mobile_number}
                          onChange={(phone) =>
                            formik.setFieldValue("mobile_number", phone)
                          }
                          inputClass={`form-control react-telinput mt-3 ${
                            formik.touched.mobile_number &&
                            formik.errors.mobile_number
                              ? "is-invalid"
                              : ""
                          }`}
                          containerClass="phone-input-container"
                        />
                        {formik.touched.mobile_number &&
                        formik.errors.mobile_number ? (
                          <div className="invalid-feedback">
                            {formik.errors.mobile_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* row-4 */}
                  <div className="row">
                    {/* Roll Number Field */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="address">Roll Number</label>
                        <input
                          id="roll_number"
                          name="roll_number"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.roll_number &&
                            formik.errors.roll_number
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter Your Roll Number"
                          {...formik.getFieldProps("roll_number")}
                        />
                        {formik.touched.roll_number &&
                        formik.errors.roll_number ? (
                          <div className="invalid-feedback">
                            {formik.errors.roll_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Graduation year */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="graduation_year">Graduation Year</label>
                        <div className="dropdown-container">
                          <select
                            id="graduation_year"
                            name="graduation_year"
                            className={`form-control mt-3 ${
                              formik.touched.graduation_year &&
                              formik.errors.graduation_year
                                ? "is-invalid"
                                : ""
                            }`}
                            value={formik.values.graduation_year}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Year</option>
                            {Array.from(
                              { length: 50 }, // Generate the last 50 years
                              (_, i) => new Date().getFullYear() - i
                            ).map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <span className="dropdown-icon">
                            <i className="ri-arrow-down-s-line"></i>
                          </span>
                        </div>
                        {formik.touched.graduation_year &&
                        formik.errors.graduation_year ? (
                          <div className="invalid-feedback">
                            {formik.errors.graduation_year}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* row-5 */}
                  <div className="row">
                    {/* Degree */}
                    <div className="col-6">
                      <div className="form-group mt-3 position-relative">
                        <label htmlFor="degree">Degree</label>
                        <div className="dropdown-container">
                          <select
                            id="degree"
                            name="degree"
                            className={`form-control mt-3  ${
                              formik.touched.degree && formik.errors.degree
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleDegreeChange(e);
                            }}
                            value={formik.values.degree}
                          >
                            <option value="">Select Degree</option>
                            {degreeOptions.map((degree) => (
                              <option key={degree.value} value={degree.value}>
                                {degree.label}
                              </option>
                            ))}
                          </select>
                          <span className="dropdown-icon">
                            <i className="ri-arrow-down-s-line"></i>
                          </span>
                        </div>
                        {formik.touched.degree && formik.errors.degree ? (
                          <div className="invalid-feedback">
                            {formik.errors.degree}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Course */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="course">Course</label>
                        <div className="dropdown-container">
                          <select
                            id="course"
                            name="course"
                            className={`form-control mt-3 ${
                              formik.touched.course && formik.errors.course
                                ? "is-invalid"
                                : ""
                            }`}
                            {...formik.getFieldProps("course")}
                          >
                            <option value="">Select Course</option>
                            {courses.map((course, index) => (
                              <option key={index} value={course}>
                                {course}
                              </option>
                            ))}
                          </select>
                          <span className="dropdown-icon">
                            <i className="ri-arrow-down-s-line"></i>
                          </span>
                        </div>
                        {formik.touched.course && formik.errors.course ? (
                          <div className="invalid-feedback">
                            {formik.errors.course}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* row-6 */}
                  <div className="row">
                    {/* Country */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="country">Country</label>
                        <div className="dropdown-container">
                          <select
                            id="country"
                            name="country"
                            className={`form-control mt-3 ${
                              formik.touched.country && formik.errors.country
                                ? "is-invalid"
                                : ""
                            }`}
                            {...formik.getFieldProps("country")}
                          >
                            <option value="" label="Select your country" />
                            <option
                              value="United States"
                              label="United States"
                            />
                            <option value="Canada" label="Canada" />
                            <option
                              value="United Kingdom"
                              label="United Kingdom"
                            />
                            <option value="India" label="India" />
                            {/* Add more countries as needed */}
                          </select>
                          <span className="dropdown-icon">
                            <i className="ri-arrow-down-s-line"></i>
                          </span>
                        </div>
                        {formik.touched.country && formik.errors.country ? (
                          <div className="invalid-feedback">
                            {formik.errors.country}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Current City of Residence */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="name">Current City of Residence</label>
                        <input
                          id="cor"
                          name="cor"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.residence && formik.errors.residence
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter Your Residence"
                          {...formik.getFieldProps("residence")}
                        />
                        {formik.touched.residence && formik.errors.residence ? (
                          <div className="invalid-feedback">
                            {formik.errors.residence}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* row-7 */}
                  <div className="row">
                    {/* Facebook Id */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="address">Facebook Id</label>
                        <input
                          id="fb_id"
                          name="fb_id"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.fb_id && formik.errors.fb_id
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="https://facebook.com/username"
                          {...formik.getFieldProps("fb_id")}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value && !value.startsWith("http")) {
                              value = `https://facebook.com/${value}`;
                            }
                            formik.setFieldValue("fb_id", value);
                          }}
                        />
                        <small className="form-text text-muted">
                          <b>Enter Your Username (without http://).</b>
                        </small>
                        {formik.touched.fb_id && formik.errors.fb_id ? (
                          <div className="invalid-feedback">
                            {formik.errors.fb_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Linkedin Id */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="address">Linkedin Id</label>
                        <input
                          id="linkedin_id"
                          name="linkedin_id"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.linkedin_id &&
                            formik.errors.linkedin_id
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="https://linkedin.com/u"
                          {...formik.getFieldProps("linkedin_id")}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value && !value.startsWith("http")) {
                              value = `https://linkedin.com/in/${value}`;
                            }
                            formik.setFieldValue("linkedin_id", value);
                          }}
                        />
                        <small className="form-text text-muted">
                          <b>Enter Your Username (without http://).</b>
                        </small>
                        {formik.touched.linkedin_id &&
                        formik.errors.linkedin_id ? (
                          <div className="invalid-feedback">
                            {formik.errors.linkedin_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* row-8*/}
                  {/* <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3">
                      <button
                        type="button"
                        className="default-btn btn btn-block"
                        onClick={handleNext}
                      >
                        Next <i className="flaticon-next mt-1"></i>
                      </button>
                    </div>
                  </div> */}
                </>
              )}
              {step === 2 && (
                <>
                  {/* row-9 */}
                  <div className="row">
                    {/* Employment Type */}
                    <div className="col-12">
                      <div className="form-group mt-3">
                        <h6>Employment Type</h6>
                        <div className="row">
                          <div className="col-4">
                            <input
                              type="checkbox"
                              name="employmentType"
                              value="1"
                              id="employed"
                              checked={employmentType === "1"}
                              onChange={handleEmploymentChange}
                            />
                            <label htmlFor="employed" className="gender">
                              Employed
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="checkbox"
                              name="employmentType"
                              value="2"
                              id="selfEmployed"
                              checked={employmentType === "2"}
                              onChange={handleEmploymentChange}
                            />
                            <label htmlFor="selfEmployed" className="gender">
                              Self Employed
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="checkbox"
                              name="employmentType"
                              value="3"
                              id="other"
                              checked={employmentType === "3"}
                              onChange={handleEmploymentChange}
                            />
                            <label htmlFor="other" className="gender">
                              Other
                            </label>
                          </div>
                        </div>
                        {formik.errors.employmentType &&
                          formik.touched.employmentType && (
                            <div className="invalid-feedback">
                              {formik.errors.employmentType}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  {/* row-10 */}
                  {(employmentType === "1" || employmentType === "2") && (
                    <div className="row">
                      {/* Current Job Information */}
                      <div className="col-12">
                        <div className="form-group mt-3">
                          <h6>Current Job Information</h6>
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="company_name" className="gender">
                                Current Company
                              </label>
                              <input
                                id="company_name"
                                name="company_name"
                                type="text"
                                className={`form-control mt-3 ${
                                  formik.touched.company_name &&
                                  formik.errors.company_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Your Company "
                                {...formik.getFieldProps("company_name")}
                              />
                              {formik.touched.company_name &&
                                formik.errors.company_name && (
                                  <div className="invalid-feedback">
                                    {formik.errors.company_name}
                                  </div>
                                )}
                            </div>
                            <div className="col-3">
                              <label
                                htmlFor="current_designation"
                                className="gender"
                              >
                                Current Designation
                              </label>
                              <input
                                id="current_designation"
                                name="current_designation"
                                type="text"
                                className={`form-control mt-3 ${
                                  formik.touched.current_designation &&
                                  formik.errors.current_designation
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Your Designation "
                                {...formik.getFieldProps("current_designation")}
                              />
                              {formik.touched.current_designation &&
                                formik.errors.current_designation && (
                                  <div className="invalid-feedback">
                                    {formik.errors.current_designation}
                                  </div>
                                )}
                            </div>
                            <div className="col-3">
                              <label htmlFor="job_domain" className="gender">
                                Job Domain
                              </label>
                              <div className="dropdown-container">
                                <select
                                  id="job_domain"
                                  name="job_domain"
                                  className={`form-control mt-3 ${
                                    formik.touched.job_domain &&
                                    formik.errors.job_domain
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  {...formik.getFieldProps("job_domain")}
                                >
                                  <option value="">Select a Domain</option>
                                  <option value="architecture">
                                    Architecture
                                  </option>
                                  <option value="urban-planning">
                                    Urban Planning
                                  </option>
                                  <option value="landscape-design">
                                    Landscape Design
                                  </option>
                                  <option value="interior-design">
                                    Interior Design
                                  </option>
                                  <option value="sustainable-design">
                                    Sustainable Design
                                  </option>
                                  <option value="historic-preservation">
                                    Historic Preservation
                                  </option>
                                  <option value="bim-specialist">
                                    BIM Specialist
                                  </option>
                                  <option value="construction-management">
                                    Construction Management
                                  </option>
                                  <option value="structural-engineering">
                                    Structural Engineering
                                  </option>
                                  <option value="lighting-design">
                                    Lighting Design
                                  </option>
                                </select>
                                <span className="dropdown-icon">
                                  <i className="ri-arrow-down-s-line"></i>
                                </span>
                              </div>
                              {formik.touched.job_domain &&
                                formik.errors.job_domain && (
                                  <div className="invalid-feedback">
                                    {formik.errors.job_domain}
                                  </div>
                                )}
                            </div>
                            <div className="col-3">
                              <label htmlFor="working_since" className="gender">
                                Working since (year)
                              </label>
                              <div className="dropdown-container">
                                <select
                                  id="working_since"
                                  name="working_since"
                                  className={`form-control mt-3 ${
                                    formik.touched.working_since &&
                                    formik.errors.working_since
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  {...formik.getFieldProps("working_since")}
                                >
                                  <option value="">Select a Year</option>
                                  {years.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                <span className="dropdown-icon">
                                  <i className="ri-arrow-down-s-line"></i>
                                </span>
                              </div>
                              {formik.touched.working_since &&
                                formik.errors.working_since && (
                                  <div className="invalid-feedback">
                                    {formik.errors.working_since}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* row-11 */}
                  <div className="row">
                    {/* Interested Roles or Activity */}
                    <div className="col-12">
                      <div className="form-group mt-3">
                        <h6>Interested Roles or Activity</h6>
                        <div className="row">
                          <div className="col-2">
                            <input
                              type="checkbox"
                              name="interested_roles"
                              value="1"
                              id="employed"
                              checked={formik.values.interested_roles.includes(
                                "1"
                              )}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                formik.setFieldValue(
                                  "interested_roles",
                                  isChecked
                                    ? [...formik.values.interested_roles, "1"]
                                    : formik.values.interested_roles.filter(
                                        (val) => val !== "1"
                                      )
                                );
                              }}
                            />
                            <label htmlFor="employed" className="gender">
                              Architect
                            </label>
                          </div>
                          <div className="col-3">
                            <input
                              type="checkbox"
                              name="interested_roles"
                              value="2"
                              id="selfEmployed"
                              checked={formik.values.interested_roles.includes(
                                "2"
                              )}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                formik.setFieldValue(
                                  "interested_roles",
                                  isChecked
                                    ? [...formik.values.interested_roles, "2"]
                                    : formik.values.interested_roles.filter(
                                        (val) => val !== "2"
                                      )
                                );
                              }}
                            />
                            <label htmlFor="selfEmployed" className="gender">
                              Urban Planner
                            </label>
                          </div>
                          <div className="col-4">
                            <input
                              type="checkbox"
                              name="interested_roles"
                              value="3"
                              id="conceptSketch"
                              checked={formik.values.interested_roles.includes(
                                "3"
                              )}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                formik.setFieldValue(
                                  "interested_roles",
                                  isChecked
                                    ? [...formik.values.interested_roles, "3"]
                                    : formik.values.interested_roles.filter(
                                        (val) => val !== "3"
                                      )
                                );
                              }}
                            />
                            <label htmlFor="conceptSketch" className="gender">
                              Concept Sketching & Modeling
                            </label>
                          </div>
                          <div className="col-3">
                            <input
                              type="checkbox"
                              name="interested_roles"
                              value="4"
                              id="codeCompliance"
                              checked={formik.values.interested_roles.includes(
                                "4"
                              )}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                formik.setFieldValue(
                                  "interested_roles",
                                  isChecked
                                    ? [...formik.values.interested_roles, "4"]
                                    : formik.values.interested_roles.filter(
                                        (val) => val !== "4"
                                      )
                                );
                              }}
                            />
                            <label htmlFor="codeCompliance" className="gender">
                              Code Compliance
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* row-12 */}
                  <div className="row">
                    {/* Graduation year */}
                    <div className="col-6">
                      <div className="form-group mt-3">
                        <label htmlFor="name">
                          Skills or Area of Specialization
                        </label>
                        <input
                          id="skills"
                          name="skills"
                          type="text"
                          className={`form-control mt-3 ${
                            formik.touched.skills && formik.errors.skills
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter Your Name"
                          {...formik.getFieldProps("skills")}
                        />
                        {formik.touched.skills && formik.errors.skills ? (
                          <div className="invalid-feedback">
                            {formik.errors.skills}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="col-1"></div>
                    <div className="col-5">
                      <button
                        type="button"
                        className="default-btn btn btn-block NextButton"
                        onClick={handlePrev}
                      >
                        Previous
                      </button>
                      <button
                        type="submit"
                        className="default-btn btn btn-block NextButton"
                      >
                        Submit
                      </button>
                    </div> */}
                  </div>
                </>
              )}
              <div className="form-buttons ">
                {step > 1 && (
                  <div className="row Button1">
                    <div className="col-5 ">
                      <button
                        type="button"
                        className="default-btn btn btn-block NextButton"
                        onClick={handlePrev}
                      >
                        Previous
                      </button>
                    </div>
                    {step === 2 && (
                      <div className="col-5">
                        <button
                          type="submit"
                          className="default-btn btn btn-block NextButton"
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {step < 2 && (
                  <div className="row">
                    <div className="col-5 NextButton1">
                      <button
                        type="button"
                        className="default-btn btn btn-block NextButton "
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!--End register Area--> */}
    </>
  );
};
