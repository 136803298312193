import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const CampusFitnessDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Premium Fitness Center at McGAN'S Ooty School of Architecture |
            Wellness and Fitness in Ooty, Tamil Nade
          </title>
          <meta
            name="description"
            content="Boost your physical and mental well-being with McGAN'S Ooty School of Architecture’s fitness center. Our gym provides top fitness equipment, personalized training, and wellness programs in Ooty, Tamil Nadu."
          />
          <meta
            name="keywords"
            content="Fitness center in Ooty, Gym for students in Tamil Nadu, Campus fitness facilities Ooty, Architecture school fitness center, Best fitness center near Ooty, Health and wellness Ooty campus, Fitness for architecture students, Student gym facilities in Tamil Nadu, Physical fitness and wellness Ooty, College fitness center for students, Wellness programs Ooty campus, Fitness activities in Ooty, Health club in Ooty for students, McGAN's School fitness center."
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/campus-fitnessdetails"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Fitness Center at McGAN'S Ooty School of Architecture
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus Fitness Center</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/campus">Campus</Link>
                </li>
                <li className="navspace">Fitness Center</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Academic Details Area--> */}
        <div className="academics-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="academics-details">
                  <div className="details">
                    <h2>Get Fit, Stay Healthy</h2>
                    <p>
                      Fitness is key to overall well-being. Whether you're
                      looking to lose weight, build muscle, or just feel better,
                      exercise is the foundation of a healthy lifestyle.
                    </p>

                    <div className="details-images">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/campusfit1.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="Fitness center in Ooty"
                            alt="Fitness center in Ooty"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/campusfit3.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="Gym for students in Tamil Nadu"
                            alt="Gym for students in Tamil Nadu"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/campusfit2.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="Campus fitness facilities Ooty"
                            alt="Campus fitness facilities Ooty"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prepare-exam">
                    <h3>Transform Your Body and Mind</h3>
                    <p>
                      Fitness is not just about physical appearance; it’s about
                      enhancing mental health and building resilience. Regular
                      exercise improves mood, reduces stress, and helps you feel
                      confident and strong.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Benefits of Regular Exercise</h4>
                          <p>
                            Engaging in regular physical activity boosts heart
                            health, enhances mental clarity, and increases
                            energy levels, contributing to a happier, healthier
                            life.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Building a Fitness Routine</h4>
                          <p>
                            Establishing a consistent fitness routine is
                            essential for progress. Start with manageable goals,
                            focusing on a mix of strength, cardio, and
                            flexibility.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Nutrition and Fitness</h4>
                          <p>
                            What you eat plays a critical role in achieving
                            fitness goals. A balanced diet with proper protein,
                            healthy fats, and vitamins fuels the body for
                            optimal performance.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Staying Motivated</h4>
                          <p>
                            Staying committed to fitness requires persistence.
                            Set small, achievable targets and celebrate progress
                            to maintain motivation and track your growth.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Academic Details Area--> */}
      </div>
    </>
  );
};
