import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { getStaffView } from "../server/apihelper";
import { Link, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const picture = process.env.REACT_APP_API_URL;

export const StaffDetails = () => {
  const [staffView, setStaffsView] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getStaffView(id);
        setStaffsView(res.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchData();
  }, [id]);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };
  return (
    <>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h1>Staff</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/staff">Staff</Link>
                </li>
                <li className="navspace">Staff Details</li>
                {/* <img
                  src={`${picture}/assets/images/staffs/staffDetails.jpg`}
                  alt="Image"
                /> */}
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Courses Details Area--> */}
        <div className="courses-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              {staffView ? (
                <div className="col-lg-8">
                  <div className="courses-details">
                    <div className="courses-card">
                      <h2>{staffView.name}</h2>
                      {/* <div className="img">
                        <img src={staffView.profile} alt={staffView.name} />
                      </div> */}
                      <p classNameName="text-justify">
                        {staffView.description}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <span>"No Data"</span>
              )}
              {staffView ? (
                <div className="col-lg-4 staffstyle2">
                  <div className="enroll-courses">
                    <div className="enroll-img">
                      <img
                        className="staffImage"
                        src={staffView.profile}
                        alt={staffView.name}
                      />
                    </div>
                    <div className="list">
                      <ul>
                        <li>
                          <span>Designition :</span>
                          {staffView.designition}
                        </li>
                        <li>
                          <span>Qualification :</span>
                          {staffView.qualification}
                        </li>
                        <li>
                          <span>Experience :</span>
                          {staffView.experience} years
                        </li>
                        <li>
                          <span>Department :</span>
                          {staffView.department}
                        </li>

                        <li>
                          <span>Subject Handling :</span>
                          {staffView.std}
                        </li>
                      </ul>
                    </div>
                    {/* <a href="courses.html" class="default-btn btn">
                    Enroll Courses
                  </a> */}
                  </div>
                </div>
              ) : (
                <span>"No Data"</span>
              )}
            </div>
          </div>
        </div>
        {/* <!--End Courses Details Area--> */}
      </div>
    </>
  );
};
