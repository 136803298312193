import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Campus = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [experience, setExperience] = useState(0);
  const [students, setStudents] = useState(0);
  const [nationalities, setNationalities] = useState(0);
  const [internationalStudents, setInternationalStudents] = useState(0);
  const [majors, setMajors] = useState(0);

  // Function to increment counters
  const animateCounter = (target, setValue, duration = 2000) => {
    let start = 0;
    const stepTime = Math.abs(Math.floor(duration / target));

    const increment = setInterval(() => {
      start += 1;
      if (start >= target) {
        clearInterval(increment);
        setValue(target);
      } else {
        setValue(start);
      }
    }, stepTime);
  };

  // Trigger the animation when the component mounts
  useEffect(() => {
    animateCounter(65, setExperience);
    animateCounter(30, setStudents); // 30k as example
    animateCounter(2, setNationalities);
    animateCounter(70, setInternationalStudents);
    animateCounter(12, setMajors);
  }, []);
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Top Architecture College Campus in Tamil Nadu | McGAN'S Ooty
            Facilities
          </title>
          <meta
            name="description"
            content="Experience the best campus facilities at McGAN'S Ooty School of Architecture, Tamil Nadu's top architecture college. Enjoy a world-class library, hostel, playground, fitness center, and studio for architecture students."
          />
          <meta
            name="keywords"
            content="Top Architecture College Tamil Nadu, 
Architecture Campus Tamil Nadu, 
McGAN'S Ooty Campus Facilities, 
Best Architecture College Hostel Tamil Nadu, 
Architecture Library and Playground Tamil Nadu, 
Fitness Center for Architecture Students, 
Leading Architecture College Campus"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/campus" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          World-Class Campus Facilities at McGAN'S Ooty School of Architecture
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">Campus</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Campus Life Area--> */}
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="single-campus-card">
                  <div className="img">
                    <Link to="/campus-playgrounddetails">
                      <img
                        src="assets/images/campus-life/Untitled-1.png"
                        style={{
                          width: "356px",
                          height: "251px",
                        }}
                        title="Top Architecture College Tamil Nadu"
                        alt="Top Architecture College Tamil Nadu"
                      />
                    </Link>
                  </div>
                  <div className="campus-content">
                    <span>Playground</span>
                    <Link to="/campus-playgrounddetails">
                      <h3>Explore, Dream, Play – The Playground of Wonders!</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-campus-card">
                  <div className="img">
                    <Link to="/campus-fitnessdetails">
                      <img
                        src="assets/images/campus-life/fitness.png"
                        style={{
                          width: "356px",
                          height: "251px",
                        }}
                        title="Architecture Campus Tamil Nadu"
                        alt="Architecture Campus Tamil Nadu"
                      />
                    </Link>
                  </div>
                  <div className="campus-content">
                    <span>Fitness Center</span>
                    <Link to="/campus-fitnessdetails">
                      <h3>Stronger Every Day, One Rep at a Time!</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-campus-card">
                  <div className="img">
                    <Link to="/campus-messdetails">
                      <img
                        src="assets/images/campus-life/canteen.png"
                        style={{
                          width: "356px",
                          height: "251px",
                        }}
                        title="McGAN'S Ooty Campus Facilities"
                        alt="McGAN'S Ooty Campus Facilities"
                      />
                    </Link>
                  </div>
                  <div className="campus-content">
                    <span>Mess</span>
                    <Link to="/campus-messdetails">
                      <h3>Where Good Food and Great Conversations Meet!</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-campus-card">
                  <div className="img">
                    <Link to="/campus-studiodetails">
                      <img
                        src="assets/images/campus-life/lab.png"
                        style={{
                          width: "356px",
                          height: "251px",
                        }}
                        title="Best Architecture College Hostel Tamil Nadu"
                        alt="Best Architecture College Hostel Tamil Nadu"
                      />
                    </Link>
                  </div>
                  <div className="campus-content">
                    <span>Studio</span>
                    <Link to="/campus-studiodetails">
                      <h3>
                        Shaping the Future Through Research and Exploration!
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-campus-card">
                  <div className="img">
                    <Link to="/campus-librarydetails">
                      <img
                        src="assets/images/campus-life/library.png"
                        style={{
                          width: "356px",
                          height: "251px",
                        }}
                        title="Architecture Library and Playground Tamil Nadu"
                        alt="Architecture Library and Playground Tamil Nadu"
                      />
                    </Link>
                  </div>
                  <div className="campus-content">
                    <span>Library</span>
                    <Link to="/campus-librarydetails">
                      <h3>Explore, Learn, Grow – The Power of Reading!</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-campus-card">
                  <div className="img">
                    <Link to="/campus-hosteldetails">
                      <img
                        src="assets/images/campus-life/Homehostel.png"
                        style={{
                          width: "356px",
                          height: "251px",
                        }}
                        title="Fitness Center for Architecture Students"
                        alt="Fitness Center for Architecture Students"
                      />
                    </Link>
                  </div>
                  <div className="campus-content">
                    <span>Hostel</span>
                    <Link to="/campus-hosteldetails">
                      <h3>Your Home Away from Home!</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Campus Life Area--> */}

        {/* <!--Start Campus Safety--> */}
        <div className="campus-safety-area pt-100 pb-70 bg-f4f6f9">
          <div className="container">
            <div className="section-title">
              <h2>Campus Safety</h2>
              <p>
                Ensuring a secure campus with round-the-clock patrols, safety
                resources, and a commitment to student well-being and peace of
                mind.
              </p>
            </div>

            <div className="counter-area">
              <div className="row">
                <div className="col">
                  <div className="counter-card">
                    <h3>
                      {experience}
                      <span className="target">+</span>
                    </h3>
                    <p>Years Of Experience</p>
                  </div>
                </div>
                <div className="col">
                  <div className="counter-card">
                    <h3>
                      {students}
                      <span className="target heading-color">k</span>
                      <span className="target">+</span>
                    </h3>
                    <p>Total Students</p>
                  </div>
                </div>
                <div className="col">
                  <div className="counter-card">
                    <h3>
                      {nationalities}
                      <span className="target heading-color">k</span>
                      <span className="target">+</span>
                    </h3>
                    <p>Library Total Book</p>
                  </div>
                </div>
                <div className="col">
                  <div className="counter-card">
                    <h3>
                      {internationalStudents}
                      <span className="target">%</span>
                    </h3>
                    <p>International Students</p>
                  </div>
                </div>
                <div className="col">
                  <div className="counter-card">
                    <h3>
                      {majors}
                      <span className="target">+</span>
                    </h3>
                    <p>Different Majors</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Campus Safety--> */}

        {/* <!--Start Student Life Area--> */}
        <div className="student-life-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Student Life</h2>
              <p>
                Experience a supportive environment where friendships flourish,
                passions ignite, and every day is an opportunity to learn and
                explore.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="student-life-card">
                  {/* <img
                    src="assets/images/stundent-life/student-life-1.jpg"
                    alt="Image"
                  /> */}
                  <img
                    src="assets/images/campus/trecking.png"
                    style={{
                      width: "546px",
                      height: "434px",
                    }}
                    title="Leading Architecture College Campus"
                    alt="Leading Architecture College Campus"
                  />
                  <div className="tags">
                    <p>Trecking</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="student-life-card">
                  <img
                    src="assets/images/stundent-life/student-life-2.png"
                    style={{
                      width: "546px",
                      height: "201px",
                    }}
                    title="student-life-2"
                    alt="student-life-2"
                  />
                  <div className="tags">
                    <p>Cyclying</p>
                  </div>
                </div>
                <div className="student-life-card">
                  <img
                    src="assets/images/stundent-life/student-life-3.jpg"
                    style={{
                      width: "546px",
                      height: "201px",
                    }}
                    title="student-life-3"
                    alt="student-life-3"
                  />
                  <div className="tags">
                    <p>Health and Wellness</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Student Life Area--> */}
      </div>
    </>
  );
};
