import logo from "./logo.svg";
import "./App.css";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Main } from "./components/main";
import SplashScreen from "./splashscreen/SplashScreen";
import Scrolltotop from "./Sctolltotop";
import { Alumni } from "./components/alumni";
import { Campus } from "./components/campus";
import { CampusPlaygroundDetails } from "./components/campus-playgrounddetails";
import { CampusFitnessDetails } from "./components/campus-fitnessdetails";
import { CampusMessDetails } from "./components/campus-messdetails";
import { CampusStudioDetails } from "./components/campus-studiodetails";
import { CampusLibraryDetails } from "./components/campus-librarydetails";
import { CampusHostelDetails } from "./components/campus-hosteldetails";
import { Staff } from "./components/staff";
import { StaffDetails } from "./components/staff -details";
import { Carrers } from "./components/carrers";
import { CarrersDetails } from "./components/carrers-details";
import { StudentStoryDetails } from "./components/student-story-details";
import { About } from "./components/about";
import { Achievements } from "./components/achievements";
import { Undergraduate } from "./components/undergraduate";
import { Postgraduate } from "./components/postgraduate";
import { Diploma } from "./components/diploma";
import { InternationalStudent } from "./components/international-student";
import { MArchApplication } from "./components/march-application";
import { BArchApplication } from "./components/barch-application";
import { DiplomaApplication } from "./components/diploma-application";
import { Gallery } from "./components/gallery";
import { Placement } from "./components/placement";
import { News } from "./components/news";
import { Events } from "./components/events";
import { NewsDetails } from "./components/news-details";
import { EventDetails } from "./components/event-details";
import { Contact } from "./components/contact";
import PayamentCard from "./components/rezor/PayamentCard";
import SuccessPage from "./components/rezor/SuccessPage";
import Cancel from "./components/rezor/Cancel";
import SuccessPageNotPlan from "./components/rezor/SuccessPageNotPlan";
import WelcomeCard from "./components/rezor/WelcomeCard";
import CookieConsent from "./components/CookieConsent";
import CookieConsentComponent from "./components/CookieConsent";
import { Registration } from "./components/registration";
import { Announcement } from "./components/announcement";
import { Helmet } from "react-helmet";
import TermsConditions from "./components/terms-conditions";
import PrivacyPolicy from "./components/privacy-policy";
// import { ThemeToggle } from "./togglethemebar/toggletheme";
function App() {
  return (
    <div className="App">
      {/* <Suspense fallback={<div>Loading...</div>}></Suspense> */}
      <Suspense fallback={<SplashScreen />}>
        <BrowserRouter>
          <Header />
          {/* <Announcement /> */}
          <Scrolltotop />
          <CookieConsentComponent />

          <Routes>
            {/* Home */}
            <Route
              path="/"
              element={
                <>
                  <Announcement />
                  <Main />
                </>
              }
            />
            {/*Alumni */}
            <Route path="/alumni" element={<Alumni />} />
            {/*Campus */}
            <Route path="/campus" element={<Campus />} />
            <Route
              path="/campus-playgrounddetails"
              element={<CampusPlaygroundDetails />}
            />
            <Route
              path="/campus-fitnessdetails"
              element={<CampusFitnessDetails />}
            />
            <Route path="/campus-messdetails" element={<CampusMessDetails />} />
            <Route
              path="/campus-studiodetails"
              element={<CampusStudioDetails />}
            />
            <Route
              path="/campus-librarydetails"
              element={<CampusLibraryDetails />}
            />
            <Route
              path="/campus-hosteldetails"
              element={<CampusHostelDetails />}
            />
            {/*Staff */}
            <Route path="/staff" element={<Staff />} />
            <Route path="/staff-details/:id" element={<StaffDetails />} />
            {/*Careers */}
            <Route path="/carrers" element={<Carrers />} />
            <Route path="/carrers-details/:id" element={<CarrersDetails />} />
            {/*Student Story */}
            <Route
              path="/student-story-details"
              element={<StudentStoryDetails />}
            />
            {/* Home */}
            {/* <Route path="/" element={<Main />} /> */}
            {/* About */}
            <Route path="/about-us" element={<About />} />
            {/* Achievements */}
            <Route path="/achievements" element={<Achievements />} />
            {/* Acdamic */}
            <Route path="/undergraduate" element={<Undergraduate />} />
            <Route path="/postgraduate" element={<Postgraduate />} />
            <Route path="/diploma" element={<Diploma />} />
            <Route
              path="/international-student"
              element={<InternationalStudent />}
            />
            {/* Application */}
            <Route path="/march-application" element={<MArchApplication />} />
            <Route path="/barch-application" element={<BArchApplication />} />
            <Route
              path="/diploma-application"
              element={<DiplomaApplication />}
            />
            {/* gallery */}
            <Route path="/gallery" element={<Gallery />} />
            {/* placement */}
            <Route path="/placement" element={<Placement />} />
            {/* News */}
            <Route path="/news" element={<News />} />
            <Route path="/news-details/:id" element={<NewsDetails />} />
            {/* Event */}
            <Route path="/events" element={<Events />} />
            <Route path="/event-details/:id" element={<EventDetails />} />
            {/* contect */}
            <Route path="/contact" element={<Contact />} />
            {/* connect now Registration page  */}
            <Route path="/registration" element={<Registration />} />
            {/* Razorpay */}
            <Route path="/" element={<WelcomeCard />} />
            <Route path="/query" element={<PayamentCard />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/failure" element={<SuccessPageNotPlan />} />
            <Route path="/cancel" element={<Cancel />} />
            {/* Announcement */}
            <Route path="/announcement" element={<Announcement />} />
            {/* Terms & Conditions */}
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>

          <Footer />
        </BrowserRouter>
        {/* <ThemeToggle /> */}
      </Suspense>
    </div>
  );
}

export default App;
