import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Achievements = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Achievements of McGAN’S Ooty School of Architecture | Top
            Architecture Colleges in the Nilgiris
          </title>
          <meta
            name="description"
            content="Discover the achievements of McGAN’S Ooty School of Architecture, one of the top architecture colleges in India. Recognized for excellence in architectural education, we offer B.Arch courses under Anna University and NATA coaching. Explore our milestones, including partnerships and world records, in Tamil Nadu’s Nilgiris region."
          />
          <meta
            name="keywords"
            content="Best architecture colleges in Tamil Nadu,
Top architecture colleges in India,
Architecture courses under Anna University,
B.Arch colleges in Tamil Nadu,
Architecture colleges in Coimbatore,
Architecture institutes in Ooty,
Top 10 architecture colleges in Tamil Nadu,
Best B.Arch colleges near Ooty,
Council of Architecture recognized colleges,
Colleges offering NATA coaching in Tamil Nadu,
Top-rated architecture programs in Tamil Nadu,
Affordable architecture colleges in South India,
Best design colleges in Tamil Nadu,
Architecture courses in the Nilgiris,
Colleges for architecture in Tamil Nadu,
Best colleges for B.Arch in Tamil Nadu,
Architecture colleges affiliated with Anna University,
Architecture institutes in South India,
Architecture entrance exam coaching (NATA, CEPT),
Architecture course details in Tamil Nadu,
Leading architecture colleges near Coimbatore,
Modern architecture colleges in Ooty,
Architecture colleges in hill stations,
Colleges for architecture in the Nilgiris,
Best educational institutions for architecture in India,
Architecture colleges in Ooty,
Top B.Arch colleges in the Nilgiris,
Best design institutes in Ooty,
Architecture education in Ooty hill station,
Colleges near Ooty for architecture courses,
Leading architecture colleges in Tamil Nadu’s hill areas"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/achievements" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Remarkable Milestones of McGAN’S Ooty School of Architecture
        </h1>
      </div>
      {/* Page Banner Area */}
      <div className="page-banner-area bg-2">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Achievements</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Achievements</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* Start Campus Information */}
      <div className="campus-information-area pb-70">
        <div className="container">
          <div className="students-achieve">
            <h2 className="studachie">Students Achievements</h2>
          </div>
          <div className="row align-items-center mt-5">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div className="campus-content pr-20">
                <div className="campus-title">
                  <h4>
                    McGans World Record Event 2023 - 160 Artists, 160 Paintings,
                    160 Meters, 120 Minutes
                  </h4>
                  <p className="text-justify">
                    The McGANS World Record Event 2023 brought together 160
                    talented artists who set a world record by creating
                    breathtaking paintings of India’s famous tourist spots in
                    just 2 hours. Displayed on a massive 160-meter canvas, these
                    stunning artworks captured the essence of India's diverse
                    landscapes. The event gained widespread acclaim, earning a
                    place in the prestigious India Book of Records and
                    international recognition. This unique artistic achievement
                    showcased not only the creative brilliance of the
                    participants but also highlighted India's cultural and
                    architectural heritage on a global platform.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div className="campus-image pl-20">
                <img
                  src="assets/images/achievements/achievement.png"
                  style={{ width: "450px", height: "400px" }}
                  title="McGans World Record Event"
                  alt="McGans World Record Event"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Campus Information */}

      {/* Start Campus Information */}
      <div className="campus-information-area pb-70">
        <div className="container">
          <div className="row align-items-center mt-5">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div className="campus-image pl-20">
                <img
                  src="assets/images/achievements/achievement2.png"
                  style={{ width: "450px", height: "400px" }}
                  title="MoU with CEPT University"
                  alt="MoU with CEPT University"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos-once="true"
            >
              <div className="campus-content pr-20">
                <div className="campus-title">
                  <h2>MoU with CEPT University</h2>
                  <p className="text-justify">
                    On February 27, 2021, CEPT University signed an MoU with
                    McGan’s Ooty School of Architecture for a mentorship program
                    aligned with India’s New Education Policy. The agreement,
                    overseen by Chief Guest Smt. Anju Sharma, Principal
                    Secretary for Higher Education, marks a significant
                    collaboration aimed at enhancing architectural education.
                    CEPT University, established in 1962 alongside NID and IIM
                    Ahmedabad, is renowned globally for its contributions to
                    education, research, and advisory work in habitat
                    disciplines. This partnership opens new opportunities for
                    academic growth and innovation at McGan’s.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Campus Information */}
    </>
  );
};
