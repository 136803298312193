import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const CampusHostelDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Best Hostel Facilities for Architecture Students | Accommodation in
            Ooty, Tamil Nadu
          </title>
          <meta
            name="description"
            content="McGAN’S Ooty School of Architecture offers the best hostel facilities for architecture students, with separate accommodations for boys and girls, ensuring a secure and study-friendly environment in Ooty, Tamil Nadu."
          />
          <meta
            name="keywords"
            content="best hostel facilities for architecture students, accommodation in Ooty Tamil Nadu, student hostel in Ooty, architecture college hostel Tamil Nadu, separate hostels for boys and girls, B.Arch student accommodation in Ooty, modern hostel for architecture students, study-friendly hostel in Nilgiris, secure student housing in Ooty, top-rated hostel near Coimbatore, NATA student hostel in Tamil Nadu, best design college hostel, architecture education hostel in Ooty"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/campus-hosteldetails"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Best Hostel Facilities for Architecture Students – Accommodation in
          Ooty, Tamil Nadu
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus Hostel </h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/campus">Campus</Link>
                </li>
                <li className="navspace">Hostel </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Academic Details Area--> */}
        <div className="academics-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="academics-details">
                  <div className="details">
                    <h2>Comfortable Living at the Hostel</h2>
                    <p>
                      The hostel provides a welcoming and affordable living
                      space for students and travelers alike. With basic
                      amenities and a supportive environment, it's an ideal
                      place to call home during your stay.
                    </p>

                    <div className="details-images">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/hostel1.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="best hostel facilities for architecture students"
                            alt="best hostel facilities for architecture students"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/hostel2.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="accommodation in Ooty Tamil Nadu"
                            alt="accommodation in Ooty Tamil Nadu"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/hostel3.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="student hostel in Ooty"
                            alt="student hostel in Ooty"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prepare-exam">
                    <h3> Embrace Comfort and Community at the Hostel</h3>
                    <p>
                      The hostel is more than just a place to stay; it's a
                      community where you can make connections, experience new
                      cultures, and enjoy a comfortable living environment at an
                      affordable price.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Cozy Rooms and Dormitories</h4>
                          <p>
                            Our rooms are designed for comfort and convenience,
                            offering cozy dormitories or private rooms with
                            essential amenities to make you feel at home.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Shared Spaces for Socializing</h4>
                          <p>
                            The common areas provide a vibrant space for
                            socializing, studying, and relaxing, helping you
                            connect with fellow residents and make lasting
                            friendships.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Nutritious Meals and Dining Options</h4>
                          <p>
                            Enjoy wholesome, balanced meals served in our dining
                            area, with options to accommodate dietary
                            preferences and promote healthy living during your
                            stay.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Convenient Location and Amenities</h4>
                          <p>
                            The hostel is strategically located near key areas
                            such as universities, transportation hubs, and
                            recreational spots, ensuring easy access to
                            everything you need.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Academic Details Area--> */}
      </div>
    </>
  );
};
