import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const CampusMessDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Healthy Student Mess Facilities at McGAN'S Ooty School of
            Architecture
          </title>
          <meta
            name="description"
            content="McGAN'S Ooty School of Architecture offers healthy and balanced meal options for students, promoting well-being and academic success. Explore our nutritious dining services in Ooty, Tamil Nadu."
          />
          <meta
            name="keywords"
            content="student mess facilities Tamil Nadu, healthy student meals Tamil Nadu, balanced diet for students Ooty, student dining options Tamil Nadu, nutritious meals for students Ooty, student cafeteria Tamil Nadu, healthy eating for students Ooty, student meal plans Tamil Nadu, student nutrition Tamil Nadu, student dining services Ooty."
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/campus-messdetails"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Nutritious Student Mess Facilities at McGAN'S Ooty School of
          Architecture
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus Mess</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/campus">Campus</Link>
                </li>
                <li className="navspace">Mess </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Academic Details Area--> */}
        <div className="academics-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="academics-details">
                  <div className="details">
                    <h2>Healthy Eating at the Mess</h2>
                    <p>
                      A well-balanced diet is essential for sustaining energy
                      and health, especially when eating at a mess.
                      Nutrition-packed meals can fuel your body and mind
                      throughout the day.
                    </p>

                    <div className="details-images">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/campusmess1.png"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="student mess facilities Tamil Nadu"
                            alt="student mess facilities Tamil Nadu"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/campusmess2.png"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="healthy student meals Tamil Nadu"
                            alt="healthy student meals Tamil Nadu"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/campusmess3.png"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title=" balanced diet for students Ooty"
                            alt=" balanced diet for students Ooty"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prepare-exam">
                    <h3>Optimizing Your Mess Menu for Better Health</h3>
                    <p>
                      Designing a mess menu with nutritious, well-balanced
                      options helps everyone maintain a healthy lifestyle. By
                      incorporating a variety of food groups, you ensure that
                      meals are both satisfying and nourishing.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4> Balanced Meal Options</h4>
                          <p>
                            At the mess, choose meals with a good mix of
                            protein, carbs, and healthy fats to ensure your body
                            gets the nutrients it needs for energy and repair.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Managing Portion Sizes</h4>
                          <p>
                            Mindful eating is crucial when dining at the mess.
                            Pay attention to portion sizes to avoid overeating
                            while ensuring you get the right amount of calories.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Healthy Snacks and Drinks</h4>
                          <p>
                            Instead of sugary snacks and sodas, opt for fruits,
                            nuts, and water. These provide natural energy and
                            hydration without the unhealthy additives.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Special Dietary Needs</h4>
                          <p>
                            The mess should cater to various dietary needs, such
                            as vegetarian, vegan, or gluten-free options, to
                            accommodate all individuals and ensure proper
                            nutrition for everyone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Academic Details Area--> */}
      </div>
    </>
  );
};
