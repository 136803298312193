import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const InternationalStudent = () => {
  const [step, setStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    gender: "",
    isInternational: "",
    nationality: "",
    residenceProof: null,
  });
  const navigate = useNavigate();
  // Initialize AOS animation
  useEffect(() => {
    AOS.init();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, residenceProof: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
    setIsModalOpen(false);
  };
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const openModal = () => {
    setStep(1);
    setIsModalOpen(true);
  };
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            B.Arch Admission for NRI/International Students | Architecture
            Colleges in Tamil Nadu
          </title>
          <meta
            name="description"
            content="Apply for B.Arch admission at McGAN'S Ooty School of Architecture, one of the best architecture colleges in Tamil Nadu, with special provisions for NRI and international students. Our serene campus in the Nilgiri Hills provides a peaceful environment for learning and personal growth. With flexible eligibility criteria and international recognition, we welcome students from across the globe to join our B.Arch program, affiliated with Anna University and accredited by the Council of Architecture."
          />
          <meta
            name="keywords"
            content="NRI B.Arch Admission Tamil Nadu,
International Students B.Arch Admission,
Best Architecture Colleges for NRI Students,
B.Arch Colleges in Tamil Nadu for Foreign Students,
B.Arch Program for International Students,
Architecture Colleges in Ooty,
B.Arch Admission for NRI Students Tamil Nadu,
Anna University B.Arch Admission for International Students,
Council of Architecture Recognized Colleges Tamil Nadu,
Top B.Arch Colleges for International Students,
NRI B.Arch Admission in South India,
Best Architecture Colleges for Foreign Nationals,
Nilgiris Architecture Colleges for NRI Students,
B.Arch Application for NRI Students"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/international-student"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          B.Arch Admission for NRI and International Students
        </h1>
      </div>
      {/* Page Banner */}
      <div className="page-banner-area bg-1">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">International Students</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">International Students</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* <!--Start Academic Details Area--> */}
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="academics-details">
                <div className="details">
                  <h2>NRI / International Students</h2>
                  <p>
                    The McGAN’S Community is highly supportive and inspirational
                    for International Students. Students will be welcomed into
                    the Unique & Serene Environment of The Nilgiri Hills,
                    facilitating a tranquil learning atmosphere. Staying in
                    Campus in our residential accommodation enhances the
                    opportunities for learning through interaction with their
                    peers and faculties
                  </p>
                  <h2>B.Arch Admission</h2>
                  <h4>Eligibility Criteria</h4>
                  <p>
                    Candidates with qualifications from foreign boards,
                    universities, and recognised institutions that the
                    Association of Indian Universities (AIU) has deemed
                    equivalent will be considered. Candidates who have passed
                    the qualifying examination from a school outside of India
                    and meet the minimum eligibility criteria set by the
                    AICTE/Council of Architecture from time to time, as well as
                    the equivalency defined by the AIU, New Delhi, are eligible
                    to apply for the B.Arch. programme. The candidate must have
                    passed all of the qualifying examination subjects from a
                    single educational board.
                  </p>
                  <p>
                    If an NRI student has studied abroad for five years in the
                    last eight years and has completed the country's mandatory
                    qualifying exams, then the student is qualified. Foreign
                    nationals who have been awarded scholarships by the
                    Government of India under various initiatives will be given
                    priority. They must apply through the Ministry of Human
                    Resource Development or the Ministry of External Affairs of
                    the Indian government.
                  </p>
                  <h6 className="insternalstyle mt-4">
                    *Certificate to be provided for the Legal Guardian of the
                    candidate by the court as per provisions of “The Guardians
                    and Wards Act 1890”
                  </h6>
                  <div className="row regiStartion-and-fee mt-5">
                    <div className="col-6">
                      <h3>Academic Qualification</h3>
                      <p>
                        {" "}
                        Candidates must have passed one of the following
                        qualifying examinations
                      </p>
                      <div className="list">
                        <ul>
                          <li>
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            HSC (Academic) / Equivalent - Candidates who have
                            passed 10+2 or its equivalent examination and
                            studied the subjects Physics, Chemistry &
                            Mathematics (PCM).
                          </li>
                          <li>
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            Diploma - Candidates who have passed 10+3 years
                            Diploma (any stream) examination recognized by
                            Central / State Governments with Mathematics as
                            compulsory subject.
                          </li>
                          <li>
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            International Baccalaureate Diploma - Candidates who
                            have passed International Baccalaureate Diploma
                            after 10 years of schooling with Mathematics as a
                            compulsory subject.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-6">
                      <h3>For 10+2 type of Foreign Boards</h3>

                      <div className="list">
                        <ul>
                          <li>
                            {" "}
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            Mark sheets of Secondary School Leaving Certificate
                            (X Standard) or equivalent.
                          </li>
                          <li>
                            {" "}
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            Higher Secondary Certificate (XII Standard) or
                            equivalent. OR
                          </li>
                          <li>
                            {" "}
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            For 8+4 type of Foreign Boards: Mark Sheets from 8th
                            to 12th standards.
                          </li>
                          <li>
                            {" "}
                            <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                            For 9+3 type of Foreign Boards: Mark Sheets from 9th
                            to 12th standards.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row regiStartion-and-fee">
                  <div className="col-6">
                    <h3>How to Apply :</h3>
                    <div className="list">
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Review the application requirements carefully.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Gather and prepare all required documents.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Fill out the application form accurately.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Submit your application on time.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Follow up if needed to confirm submission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-6">
                    <h3>Apply Application & Fees</h3>
                    <div className="privacy-policy-area pt-50 pb-70">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="privacy-content pr-20">
                              <div className="row">
                                <div className="col-1">
                                  <button
                                    className="default-btn btn restyle"
                                    onClick={() =>
                                      navigate(`/barch-application`)
                                    }
                                  >
                                    B.Arch <i className="flaticon-next"></i>
                                  </button>
                                </div>
                                <div className="col-2 buttonStyle">
                                  <button
                                    className="default-btn btn restyle1"
                                    onClick={() =>
                                      navigate(`/march-application`)
                                    }
                                  >
                                    M.Arch <i className="flaticon-next"></i>
                                  </button>
                                </div>
                                <div className="col-2 buttonStyle1">
                                  {/* <button
                                    className="default-btn btn restyle2"
                                    onClick={() =>
                                      navigate(`/diploma-application`)
                                    }
                                  >
                                    Diploma <i className="flaticon-next"></i>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Academic Details Area--> */}
    </>
  );
};
