import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { RiArrowUpSLine } from "react-icons/ri";
// import { useTheme } from "../togglethemebar/ThemeContext";

export const Footer = () => {
  // const { theme } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  let timeoutId;

  // Initialize AOS animation
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Apply Now Button Function  hover
  // const handleButtonClick = () => {
  //   setShowMenu((prev) => !prev);
  // };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop1 = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handleMouseEnter = () => {
  //   clearTimeout(timeoutId); // Cancel any timeout
  //   setShowMenu(true);
  // };

  // const handleMouseLeave = () => {
  //   timeoutId = setTimeout(() => {
  //     setShowMenu(false);
  //   }, 1000); // Keep the menu active for 2 seconds after mouse leaves
  // };

  // useEffect(() => {
  //   return () => clearTimeout(timeoutId);
  // }, []);

  // Apply Now Button Function (only on click)
  const handleButtonClick = () => {
    setShowMenu((prev) => !prev); // Toggle the menu visibility on click
  };

  // Handle option click and close the menu
  const handleOptionClick = (path) => {
    navigate(path); // Navigate to the selected path
    setShowMenu(false); // Close the menu
  };

  return (
    <>
      {/* <div className={theme === "dark" ? "footer-dark" : "footer-light"}> */}
      {/* <!--Start Footer Area--> */}
      <div className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-logo-area">
                <Link to="/">
                  <img
                    src="assets/images/Mccans' logo white.svg"
                    style={{ width: "270px", height: "84px" }}
                    title="Footer Logo"
                    alt="footerlogo"
                    width="270px"
                  />
                </Link>
                <p>
                  McGAN'S Ooty School of Architecture Perar, Kotagiri Road,
                  Ooty, The Nilgiris, Tamilnadu - 643 002 .
                </p>
                <div className="contact-list">
                  <ul>
                    <li>
                      <a href="tel:+7904257993">
                        +91 79042 57993 / 93445 11168.
                      </a>
                    </li>
                    <li>
                      <a href="tel:+9488336599">
                        For Admission - +91 94883 36599.
                      </a>
                    </li>
                    <li>
                      <a href="tel:04232225970">0423 - 2225970 .</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet">
                <h3>Quick Links</h3>
                <div className="list">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    <li>
                      <Link to="/undergraduate">Academic</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/placement">Placement</Link>
                    </li>
                    <li>
                      <Link to="/student-story-details">Student Story</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet">
                <h3>Our Campus</h3>
                <div className="list">
                  <ul>
                    <li>
                      <Link to="/campus-playgrounddetails">Playground</Link>
                    </li>
                    <li>
                      <Link to="/campus-fitnessdetails">Fitness Center</Link>
                    </li>
                    <li>
                      <Link to="/campus-messdetails">Mess</Link>
                    </li>
                    <li>
                      <Link to="/campus-studiodetails">Lab</Link>
                    </li>
                    <li>
                      <Link to="/campus-librarydetails">Library</Link>
                    </li>
                    <li>
                      <Link to="/campus-hosteldetails">Hostel</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widjet">
                <h3>Academics</h3>
                <div className="list">
                  <ul>
                    <li>
                      <Link to="/undergraduate">Undergraduate</Link>
                    </li>
                    <li>
                      <Link to="/postgraduate">Postgraduate</Link>
                    </li>
                    <li>
                      <Link to="/diploma">Diploma</Link>
                    </li>
                    <li>
                      <Link to="/international-student">
                        International Student
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="shape">
            <img
              src="assets/images/shape-1.png"
              style={{ width: "829px", height: "351px" }}
              title="Shape"
              alt="Shape"
            />
          </div>
        </div>
      </div>
      {/* <!--End Footer Area--> */}

      {/* <!--Start Copyright Area--> */}
      <div className="copyright-area">
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="social-content">
                  <ul>
                    <li>
                      <span>Follow Us On</span>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/archmcgan-ooty-2b1897304?utm_source=share&amp;utm_campaign=share_via&amp;utm_content=profile&amp;utm_medium=android_app"
                        target="_blank"
                      >
                        <i className="ri-linkedin-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/mcgansarch?t=2DUahM0qDkcR8Q3HJFR94g&s=08"
                        target="_blank"
                      >
                        <i className="ri-twitter-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@mcgansooty3246"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-youtube-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/mcgans_arch?igsh=c3lmN3pkc3dhajVq"
                        target="_blank"
                      >
                        <i className="ri-instagram-line"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/share/1BTYPqWFPi/"
                        target="_blank"
                      >
                        <i className="ri-facebook-fill"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                <div className="copy">
                  <p>
                    © Copyright {new Date().getFullYear()} by McGAN'S Ooty
                    School of Architecture. Handcrafted with ♥ by{" "}
                    <a
                      href="https://www.dotwings.in/"
                      target="_blank"
                      class="ListItem"
                    >
                      <b>Dotwings</b>.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Copyright Area--> */}

      {/* Apply Now */}
      <div
        className={`go-top ${isVisible ? "active" : ""}`}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <div className="button-container">
          <button
            type="button"
            className="default-btn btn admission colourstyle"
            onClick={handleButtonClick}
          >
            Apply Now
          </button>
        </div>

        {/* Popup Modal */}
        {showMenu && (
          <div className="modal-overlay">
            <div className="modal-contents buttonApply">
              <div className="modal-button-row">
                <button
                  className="modal-button"
                  onClick={() => handleOptionClick("/barch-application")}
                >
                  B.Arch
                </button>
                <button
                  className="modal-button"
                  onClick={() => handleOptionClick("/march-application")}
                >
                  M.Arch
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Scroll Up Icon */}
        {isVisible && <RiArrowUpSLine onClick={scrollToTop1} />}
      </div>

      {/* End */}
      {/* <!-- Start Go Top Area --> */}
      <div
        className={`go-top ${isVisible ? "active" : ""} additional-class`}
        onClick={scrollToTop}
      >
        <i className="ri-arrow-up-s-line"></i>

        {/* <RiArrowUpSLine /> */}
      </div>
      {/* <!-- End Go Top Area --> */}
      {/* </div> */}
    </>
  );
};
