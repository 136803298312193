import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listStudentStories } from "../server/apihelper";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const StudentStoryDetails = () => {
  const [studentStoriesList, setStudentStoriesList] = useState([]);
  const [isContentVisible, setIsContentVisible] = useState(null);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const query = "page=1&items_per_page=10";
    listStudentStories(query).then((res) => {
      setStudentStoriesList(res.data);
    });
  }, []);

  const toggleContent = (index) => {
    setIsContentVisible((prevIndex) => (prevIndex === index ? null : index));
  };

  // Function to check if description is more than 20 words
  const isDescriptionLong = (description) => {
    return description.split(" ").length > 20;
  };

  // Function to get truncated description
  const getTruncatedDescription = (description) => {
    return description.split(" ").slice(0, 20).join(" ") + "...";
  };

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Student Success Stories at McGAN'S Ooty School of Architecture |
            Inspiring Journeys in Architecture
          </title>
          <meta
            name="description"
            content="Explore inspiring success stories of McGAN'S Ooty School of Architecture students. Discover how our students overcome challenges, secure internships, and achieve remarkable milestones in architecture education."
          />
          <meta
            name="keywords"
            content="Student stories McGAN'S Ooty, 
Architecture student success stories, 
Inspiring architecture students Tamil Nadu, 
McGAN'S Ooty student achievements, 
Real-world experience architecture, 
Student internship success, 
McGAN'S Ooty student testimonials"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/student-story-details"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Inspiring Student Success Stories at McGAN'S Ooty School of
          Architecture
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Student Story</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">Student Story</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* Start Campus Information */}
        <div class="health-care-area pt-70 pb-70">
          <div class="container">
            <div class="row justify-content-center">
              <h2 className="CarrersDetails1">
                Inspiring Journeys: Student Success Stories
              </h2>
              <p>
                Meet our students who turned ambition into achievement. From
                overcoming obstacles to reaching their goals, each story is a
                powerful testament to resilience and determination.
              </p>
              {studentStoriesList.map((storyDetails, index) => (
                <div className="story-card" key={index}>
                  <div className="student-photo">
                    <img
                      src={storyDetails.image}
                      style={{ width: "150px", height: "150px" }}
                      title="Student stories McGAN'S Ooty"
                      alt="Student stories McGAN'S Ooty"
                    />
                  </div>
                  <div className="student-details">
                    <h2>{storyDetails.student_name}</h2>
                    <h4 style={{ color: "#111d5e" }}>
                      {new Date(storyDetails.date).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </h4>
                    {/* Display truncated or full description based on content visibility */}
                    <div className="additional-content studentstory">
                      <p className="text-justify ">
                        {isContentVisible === index ||
                        !isDescriptionLong(storyDetails.description)
                          ? storyDetails.description
                          : getTruncatedDescription(storyDetails.description)}
                      </p>
                    </div>
                    {/* Button to toggle content visibility */}
                    {isDescriptionLong(storyDetails.description) && (
                      <button
                        className="read-more-btn"
                        onClick={() => toggleContent(index)}
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                        }}
                      >
                        {isContentVisible === index ? "Read Less" : "Read More"}
                        <i className="flaticon-next"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
