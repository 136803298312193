import "./SuccessPage.css";
function Cancel() {
  return (
    <div className="box">
      <div className="success alert">
        <div className="alert-body warn-message">
          <h2 className="success-heading">Return to Application</h2>
          <p className="success-text">Thank you for choosing Mcgan's.</p>
        </div>
      </div>
    </div>
  );
}
export default Cancel;
