import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const CampusLibraryDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Top Learning Hub for Architecture Students in Tamil Nadu |
            Architecture Library
          </title>
          <meta
            name="description"
            content="Explore McGAN'S Ooty School of Architecture library, a top learning hub for architecture students in Tamil Nadu, offering a vast collection of books, digital resources, and quiet study spaces to foster creativity and academic growth."
          />
          <meta
            name="keywords"
            content="architecture library Ooty, student resources Ooty, digital learning resources Tamil Nadu, academic library Ooty, architecture research materials, study library for architecture students, McGAN'S Ooty architecture hub, quiet study spaces Ooty, architectural books Tamil Nadu, library for architecture students, online learning resources Tamil Nadu, architectural journals Ooty, architecture program library, educational library Ooty, community events Ooty, research materials for architecture students, McGAN'S Ooty academic library, best library for architecture students, architectural learning resources, modern architecture library Ooty."
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link
            rel="canonical"
            href="https://mcgansarch.com/campus-librarydetails"
          />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Top Learning Hub for Architecture Students in Ooty, Tamil Nadu
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">Campus Library </h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">
                  <Link to="/campus">Campus</Link>
                </li>
                <li className="navspace">Library </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* <!--Start Academic Details Area--> */}
        <div className="academics-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="academics-details">
                  <div className="details">
                    <h2>A Hub of Knowledge and Inspiration</h2>
                    <p>
                      The library is a sanctuary for learning and creativity.
                      It’s where knowledge is preserved, and ideas flourish,
                      offering resources to inspire minds of all ages.
                    </p>

                    <div className="details-images">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/library1.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="architecture library Ooty"
                            alt="architecture library Ooty"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/library2.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="student resources Ooty"
                            alt="student resources Ooty"
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <img
                            src="assets/images/campus-detail/library3.jpg"
                            style={{
                              width: "356px",
                              height: "306px",
                            }}
                            title="digital learning resources Tamil Nadu"
                            alt="digital learning resources Tamil Nadu"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prepare-exam">
                    <h3>Unlocking the World of Learning at the Library</h3>
                    <p>
                      The library is a gateway to endless knowledge, offering a
                      wide array of books, digital resources, and learning
                      opportunities. It's a space designed to fuel curiosity and
                      foster intellectual growth.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Vast Collection of Books and Resources</h4>
                          <p>
                            Our library offers an extensive collection of books,
                            journals, and digital resources, catering to every
                            interest and field of study, ensuring access to
                            valuable information.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Quiet Study Areas for Focused Learning</h4>
                          <p>
                            Designed for concentration, our study spaces provide
                            a quiet environment for reading, research, and
                            personal study, helping you stay focused and
                            productive.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Digital Access to Knowledge</h4>
                          <p>
                            Beyond physical books, our library provides access
                            to online databases, e-books, and digital archives,
                            expanding learning opportunities for all members.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="exam-card">
                          <h4>Community Programs and Events</h4>
                          <p>
                            We host various workshops, lectures, and community
                            events to engage and educate the public, fostering a
                            love for learning and encouraging lifelong
                            education.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Academic Details Area--> */}
      </div>
    </>
  );
};
