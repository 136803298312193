import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { alumniFormCount, getContactEmail } from "../server/apihelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export const Alumni = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    subject: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [country, setCountry] = useState("");
  const [alumniData, setAlumniData] = useState({
    countryCount: 0,
    alumniFormCount: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getContactEmail(formData);
      if (response.message === "Message sent successfully!") {
        toast.success("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone_no: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await alumniFormCount();
        if (res.status) {
          setAlumniData({
            countryCount: res.data.countryCount,
            alumniFormCount: res.data.alumniFormCount,
          });
        }
      } catch (error) {
        console.error("Error fetching alumni data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            McGAN'S Ooty Alumni Network | Connect with Top Architecture
            Graduates
          </title>
          <meta
            name="description"
            content="Join the thriving McGAN'S Ooty alumni network and connect with architecture graduates from Tamil Nadu and beyond. Build professional relationships and explore career opportunities with our global alumni community."
          />
          <meta
            name="keywords"
            content="McGAN'S Ooty Alumni Network, 
Architecture Alumni Tamil Nadu, 
Connect with McGAN'S Graduates, 
Top Architecture Alumni Network, 
Alumni Community for Architecture, 
McGAN'S Alumni Tamil Nadu"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/alumni" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Stay Connected with the McGAN'S Ooty Alumni Network
        </h1>
      </div>
      <div className="page-banner-area bg-2">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Alumni</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Alumni</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Contact Us Area */}
      <div className="contact-us-area pt-100 pb-70">
        <div className="container">
          <div className="contacts-form">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-and-address">
                  <h2>Alumni</h2>
                  <p>
                    <b>
                      Engage with a vibrant community of alumni, fostering
                      connections and collaborations for a brighter future.
                    </b>
                  </p>
                  <div className="contact-and-address-content">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="contact-card Alumniformcard">
                          <div className="icon">
                            <i
                              className="ri-user-line"
                              style={{
                                width: "35px",
                                height: "35px",
                              }}
                              title="Architecture Alumni Tamil Nadu"
                              alt="Architecture Alumni Tamil Nadu"
                            ></i>
                          </div>
                          <h4>Total Alumni connected</h4>
                          <p className="totalcount">
                            {alumniData.alumniFormCount}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="contact-card Alumniformcard">
                          <div className="icon">
                            <i
                              className="ri-map-pin-line"
                              style={{
                                width: "35px",
                                height: "35px",
                              }}
                              title="Connect with McGAN'S Graduates"
                              alt="Connect with McGAN'S Graduates"
                            ></i>
                          </div>
                          <h4>Total Locations</h4>
                          <p className="totalcount">
                            {alumniData.countryCount}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 text-center btnalign">
                        <Link
                          to="/registration"
                          className="connect-btn d-flex align-item-center"
                        >
                          <span>Connect Now</span>
                          <i className="flaticon-next navspace1 mt-1"></i>
                        </Link>
                      </div>
                      <div className="col-lg-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};
