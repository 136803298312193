import "./SuccessPage.css"; // Import your CSS file for styling
import { Link } from "react-router-dom";
function SuccessPage() {
  return (
    <div className="box">
      <div className="success alert">
        <div className="alert-body success-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M9 16.17l-3.59-3.58L4 14l5 5 10-10-1.41-1.42L9 16.17z" />
          </svg>
          <h2 className="success-heading">Admission form Submitted Success!</h2>
          <p className="success-text">Thank you for choosing Mcgan's.</p>
          <p className="instruction-text">
            <b>
              Press the back button to return to the{" "}
              <Link to="/">Home Page</Link>.
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}
export default SuccessPage;
