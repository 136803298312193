import React, { useEffect, useState } from "react";
import "./splash.scss";

const SplashScreen = () => {
  const [loading, setLoading] = useState(true);
  console.log("loading", loading);
  useEffect(() => {
    // Simulating loading time
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="main">
      {loading ? (
        <div className="splash">
          <h1>Welcome to Mcgan's</h1>
          <h2>Loading...</h2>
        </div>
      ) : (
        <h1>Error</h1>
      )}
    </div>
  );
};

export default SplashScreen;
