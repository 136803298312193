import React, { useEffect, useState } from "react";
import AOS from "aos"; // AOS for animations
import "aos/dist/aos.css"; // AOS styles
import { Link, useParams } from "react-router-dom"; // Router hooks
import { getEventsView, listEvents } from "../server/apihelper"; // API helper
import $ from "jquery"; // Import jQuery
import "owl.carousel/dist/assets/owl.carousel.css"; // Carousel CSS
import "owl.carousel/dist/assets/owl.theme.default.css"; // Carousel theme CSS
import "owl.carousel"; // Owl Carousel JS
import { format } from "date-fns"; // Import date-fns for date formatting

// Ensure jQuery is available globally
window.jQuery = $;

export const EventDetails = () => {
  const [eventsView, setEventsView] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const { id } = useParams();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init();
  }, []);

  // Fetch news details based on the ID from URL params
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getEventsView(id);
        setEventsView(res.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchData();
  }, [id]);

  // Initialize Owl Carousel after eventsView is updated
  useEffect(() => {
    const carousel = $(".owl-carousel");

    if (eventsView.length > 0) {
      carousel.owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
          0: { items: 1 },
          600: { items: 2 },
          1000: { items: 3 },
        },
      });

      // Cleanup function to destroy the carousel on unmount
      return () => {
        carousel.trigger("destroy.owl.carousel");
      };
    }
  }, [eventsView]);

  // Format the date using date-fns
  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid Date";
    }

    try {
      const date = new Date(dateString);
      if (isNaN(date)) {
        throw new Error("Invalid Date");
      }
      return format(date, "EEEE do MMMM"); // Example: Thursday 14th April
    } catch (error) {
      console.error("Date parsing error:", error);
      return "Invalid Date";
    }
  };
  useEffect(() => {
    const query = "page=1&items_per_page=10";
    listEvents(query).then((res) => {
      setEventsList(res.data);
    });
  }, []);
  return (
    <>
      {/* Start Page Banner */}
      <div className="page-banner-area bg-2">
        <div className="container">
          <div className="page-banner-content">
            <h1>Events Details</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">
                <Link to="/events">Events</Link>
              </li>
              <li className="navspace">Events Details</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* Start Health Care Details */}
      <div className="health-care-details pt-100 pb-70">
        <div className="container">
          <div className="row">
            {eventsView ? (
              <div className="col-lg-8">
                <div className="health-details">
                  <div className="top-content">
                    <img src={eventsView.image} alt="Image" />
                    <h2>{eventsView.title}</h2>
                    <div className="row">
                      <div className="col-5">
                        <p>{formatDate(eventsView.date)}</p>
                      </div>
                      <div className="col-6">
                        <p>
                          <i className="ri-map-pin-line"></i>
                          {eventsView.place}
                        </p>
                      </div>
                      <div className="col-1"></div>
                    </div>
                    <p className="text-justify">{eventsView.description}</p>
                  </div>
                </div>
              </div>
            ) : (
              <span>"No Data"</span>
            )}
            <div className="col-lg-4">
              <div className="tranding">
                <h3>News List</h3>
                {eventsList.map((item, index) => (
                  <div className="tranding-box" key={index}>
                    <div className="tranding-content">
                      <Link to={`/event-details/${item.primaryId}`}>
                        <img src={item.image} alt="Image" />
                      </Link>
                      <h4>
                        <Link
                          to={`/event-details/${item.primaryId}`}
                          className={
                            item.title === eventsView.title
                              ? "active-title"
                              : ""
                          }
                        >
                          {item.title}
                        </Link>
                      </h4>
                      <Link
                        to={`/event-details/${item.primaryId}`}
                        className={
                          item.title === eventsView.title
                            ? "active-title1"
                            : "inactive-title1 "
                        }
                      >
                        {item.description.split(" ").slice(0, 3).join(" ")}...
                      </Link>
                      <Link
                        to={`/event-details/${item?.primaryId}`}
                        className="read-more-btn"
                      >
                        {" "}
                        Read More<i className="flaticon-next"></i>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Health Care Details */}
    </>
  );
};
