import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Undergraduate = () => {
  const navigate = useNavigate();

  // Initialize AOS animation
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Top B.Arch Colleges in Tamil Nadu | Best Architecture Institutes in
            Ooty & Coimbatore | CSA | Best Architecture Colleges in Coimbatore
          </title>
          <meta
            name="description"
            content="Top B.Arch colleges in Tamil Nadu, including the best architecture colleges in Coimbatore and Ooty, McGAN'S Ooty School of Architecture is a Council of Architecture recognized institute offering B.Arch courses affiliated with Anna University. Located in the Nilgiris, we provide NATA coaching, creative design, and modern architecture education, making us one of South India’s leading architecture colleges."
          />
          <meta
            name="keywords"
            content="Best architecture colleges in Tamil Nadu,
Top architecture colleges in India,
Architecture courses under Anna University,
B.Arch colleges in Tamil Nadu,
Architecture colleges in Coimbatore,
Architecture institutes in Ooty,
Top 10 architecture colleges in Tamil Nadu,
Best B.Arch colleges near Ooty,
Council of Architecture recognized colleges,
Colleges offering NATA coaching in Tamil Nadu,
Top-rated architecture programs in Tamil Nadu,
Affordable architecture colleges in South India,
Best design colleges in Tamil Nadu,
Architecture courses in the Nilgiris,
Colleges for architecture in Tamil Nadu,
Best colleges for B.Arch in Tamil Nadu,
Architecture colleges affiliated with Anna University,
Architecture institutes in South India,
Architecture entrance exam coaching (NATA, CEPT),
Architecture course details in Tamil Nadu,
Leading architecture colleges near Coimbatore,
Modern architecture colleges in Ooty,
Architecture colleges in hill stations,
Colleges for architecture in the Nilgiris,
Best educational institutions for architecture in India,
Architecture colleges in Ooty,
Top B.Arch colleges in the Nilgiris,
Best design institutes in Ooty,
Architecture education in Ooty hill station,
Colleges near Ooty for architecture courses,
Leading architecture colleges in Tamil Nadu’s hill areas"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/undergraduate" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Join Leading Architecture Colleges in Tamil Nadu for Your B.Arch
          Education
        </h1>
      </div>
      <div className="page-banner-area bg-1">
        <div className="container">
          <div className="page-banner-content">
            <h3 className="BannerHeader">Undergraduate</h3>
            <ul className="sideheader">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">Undergraduate</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* <!--Start Academic Details Area--> */}
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="academics-details">
                <div className="details">
                  <h2>Bachelor of Architecture (B.Arch)</h2>
                  <p>
                    Architecture is a demanding profession, but it can also be a
                    very interesting, creative one. Architecture is a great
                    field of study for those who are artistically talented and
                    interested in creative design and the built environment.
                    Hence, it is very important that where you choose to study.
                    McGAN’S created a beautiful setting in the valley of Perar
                    in Ooty, as the main objective taken as to stimulate the
                    young minds joins here. Apart from that beautiful inspiring
                    setting the young minds are nurtured periodically through a
                    range of studios, lectures, workshops and seminars, in which
                    individual creativity can be criticality nurtured.
                  </p>
                  <p>
                    McGAN’S have a wide range of student activities ranging from
                    Illuminati, NASA, Sports, festivals and various
                    Competitions. McGAN’S takes the students to various other
                    college symposiums to showcase their talents and nurture
                    them with eccentric thoughts.
                  </p>
                  <div className="details-images">
                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC1.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="AC1"
                          alt="AC1"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC2.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="AC2"
                          alt="AC2"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <img
                          src="assets/images/academics/AC3.jpg"
                          style={{ width: "356px", height: "378px" }}
                          title="AC3"
                          alt="AC3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="regiStartion-and-fee">
                  <h3>Eligibility</h3>
                  <p>
                    Only candidates who have the following credentials shall be
                    eligible for admission to B.Arch. Course.
                  </p>
                  <div class="list">
                    <ul>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Qualified through the recognized aptitude test in
                        Architecture (NATA or equivalent) .
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Have gone through any of the following curriculum with
                        marks as prescribed below:
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                        Secured at least 50% aggregate marks in Physics,
                        Chemistry and Mathematics (PCM) and also at least 50%
                        marks in aggregate of the 10+2 level examination;
                      </li>
                      <li>
                        <i className="ri-checkbox-circle-line iconStyle"></i> Or
                        passed 10+3 diploma examination with Mathematics as
                        compulsory subject with at least 50% marks in aggregate.
                      </li>
                      <p>
                        <b>
                          Candidates may note that no direct lateral admission
                          is allowed at any other year/stage of B.Arch. course
                          based on any qualification
                        </b>
                      </p>
                    </ul>
                  </div>
                </div>

                <div className="row regiStartion-and-fee">
                  <div className="col-6">
                    <h3>How to Apply :</h3>
                    <div className="list">
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Review the application requirements carefully.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Gather and prepare all required documents.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Fill out the application form accurately.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Submit your application on time.
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line iconStyle"></i>{" "}
                          Follow up if needed to confirm submission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-6">
                    <h3>Apply Application & Fees</h3>
                    <div className="list">
                      <ul>
                        <button
                          type="button"
                          className="default-btn btn"
                          onClick={() => navigate(`/barch-application`)}
                        >
                          Apply Now <i className="flaticon-next"></i>
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Academic Details Area--> */}
    </>
  );
};

export default Undergraduate;
