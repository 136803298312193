import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const WEB_URL = `${API_URL}website/`;
const USER_URL = `${WEB_URL}student-stories`;
const ALUMNI_URL = `${WEB_URL}alumni`;
const GALLERY_URL = `${WEB_URL}gallery`;
const PLACEMENTS_URL = `${WEB_URL}placements`;
const NEWS_URL = `${WEB_URL}news`;
const EVENTS_URL = `${WEB_URL}events`;
const STAFF_URL = `${WEB_URL}staff`;
const CAREERS_URL = `${WEB_URL}Careers`;
const CONTACT_URL = `${API_URL}contact`;
const ALUMNIFORM_URL = `${WEB_URL}alumni-form`;
const ANNOUNCEMENT_URL = `${WEB_URL}announcement`;

const limitStudentStories = async (query) => {
  const d = await axios.get(`${USER_URL}/limitlist?${query}`);
  return d.data;
};

const listStudentStories = async (query) => {
  const d = await axios.get(`${USER_URL}/list?${query}`);
  return d.data;
};

const getStudentStoriesById = (id) => {
  return axios.get(`${USER_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

// Alumni
const listAlumni = async (query) => {
  const d = await axios.get(`${ALUMNI_URL}/list?${query}`);
  return d.data;
};

const getAlumniView = (id) => {
  return axios.get(`${ALUMNI_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

// Alumni Form
const createAlumniForm = async (datas) => {
  const response = await axios.post(`${WEB_URL}add`, datas);
  console.log("Api response.data==>", response.data);
  return response.data;
};

const alumniFormCount = async () => {
  const data = await axios.get(`${WEB_URL}count`);
  console.log("alumniFormCount", data);

  return data.data;
};

// const alumniFormApproval = async (id) => {
//   const response = await axios.put(`${WEB_URL}/update/${id}`);
//   return response.data;
// };

const alumniFormApproval = async (id, updateData) => {
  const response = await axios.put(`${WEB_URL}/update/${id}`, updateData);
  return response.data;
};

// Gallery
const listGallery = async (query) => {
  const d = await axios.get(`${GALLERY_URL}/list?${query}`);
  console.log("dddddd", d);

  return d.data;
};

const getGalleryView = (id) => {
  return axios.get(`${GALLERY_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

// Placements
const listPlacements = async (query) => {
  const d = await axios.get(`${PLACEMENTS_URL}/list?${query}`);
  return d.data;
};

const getPlacementsView = (id) => {
  return axios.get(`${PLACEMENTS_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

// news
const limitNews = async (query) => {
  const d = await axios.get(`${NEWS_URL}/limitlist?${query}`);
  console.log("d ", d);
  return d.data;
};

const listNews = async (query) => {
  const d = await axios.get(`${NEWS_URL}/list?${query}`);
  return d.data;
};

const getNewsView = (id) => {
  return axios.get(`${NEWS_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

// Events
const listEvents = async (query) => {
  const d = await axios.get(`${EVENTS_URL}/list?${query}`);
  return d.data;
};

const getEventsView = (id) => {
  return axios.get(`${EVENTS_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

// Staff
const listStaff = async (query) => {
  const d = await axios.get(`${STAFF_URL}/list?${query}`);
  return d.data;
};

const getStaffView = (id) => {
  return axios.get(`${STAFF_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};
// Careers
const listCareers = async (query) => {
  const d = await axios.get(`${CAREERS_URL}/list?${query}`);
  return d.data;
};

const getCareerssView = (id) => {
  return axios.get(`${CAREERS_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};
// Razorpay
// with razorpay
// const getRazorPayOrder = async (formData) => {
//   // Create a new order
//   console.log("orderData", formData);

//   const orderResponse = await axios.post(`${API_URL}razorpay`, formData);
//   return orderResponse.data;
// };

const getRazorPayOrder = async (formData) => {
  // Create a new order
  console.log("getRazorPayOrder ---apiiii fromdata", formData);

  const orderResponse = await axios.post(`${API_URL}razorpay`, formData);
  return orderResponse.data;
};

// Contact Us Email
const getContactEmail = async (formData) => {
  const emailResponse = await axios.post(
    `${CONTACT_URL}/contact-email`,
    formData
  );
  return emailResponse.data;
};

// Announcement
const listAnnouncement = async (query) => {
  console.log("query listAnnouncement api req ", query);

  const d = await axios.get(`${ANNOUNCEMENT_URL}/list?${query}`);
  return d.data;
};
export {
  listStudentStories,
  limitStudentStories,
  getStudentStoriesById,
  listAlumni,
  getAlumniView,
  createAlumniForm,
  alumniFormApproval,
  alumniFormCount,
  listGallery,
  getGalleryView,
  listPlacements,
  getPlacementsView,
  limitNews,
  listNews,
  getNewsView,
  listEvents,
  getEventsView,
  listStaff,
  getStaffView,
  listCareers,
  getCareerssView,
  getRazorPayOrder,
  getContactEmail,
  listAnnouncement,
};
