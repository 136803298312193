import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// payment
import { useNavigate } from "react-router-dom";
import { getRazorPayOrder } from "../server/apihelper";

export const MArchApplication = () => {
  const [data, setFormData] = useState({});
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(1000);
  const razorPayScriptUrl = "https://checkout.razorpay.com/v1/checkout.js";
  const logo = "https://your-logo-url.com/logo.png"; // Your logo URL
  const navigate = useNavigate();

  const validationSchemaStep1 = Yup.object({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone_no: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    gender: Yup.string().required("Gender is required"),
    is_international_student: Yup.string().required(
      "International status is required"
    ),
    nationality: Yup.string().required("Nationality is required"),
    // residence_proof: Yup.mixed()
    //   .required("Certificate is required")
    //   .test(
    //     "fileType",
    //     "Invalid file type. Only JPG, PNG, PDF, DOC, DOCX are allowed.",
    //     (value) => {
    //       if (!value) return false;
    //       const allowedTypes = [
    //         "image/jpeg",
    //         "image/png",
    //         "application/pdf",
    //         "application/msword",
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       ];
    //       return allowedTypes.includes(value.type);
    //     }
    //   ),
    // residence_proof: Yup.mixed().required("Residence proof is required"),
  });

  const validationSchemaStep2 = Yup.object({
    board_10: Yup.string().required("Board is required"),
    percentage_10: Yup.number()
      .required("Percentage is required")
      .min(0, "Percentage must be a positive number")
      .max(100, "Percentage must be between 0 and 100"),
    yop_10: Yup.number()
      .required("Year of Passing is required")
      .min(1900, "Year must be a valid year"),
    // certificate_10: Yup.mixed()
    //   .required("Certificate is required")
    //   .test(
    //     "fileType",
    //     "Invalid file type. Only JPG, PNG, PDF, DOC, DOCX are allowed.",
    //     (value) => {
    //       if (!value) return false;
    //       const allowedTypes = [
    //         "image/jpeg",
    //         "image/png",
    //         "application/pdf",
    //         "application/msword",
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       ];
    //       return allowedTypes.includes(value.type);
    //     }
    //   ),
    board_12: Yup.string().required("Board is required"),
    percentage_12: Yup.number()
      .required("Percentage is required")
      .min(0, "Percentage must be a positive number")
      .max(100, "Percentage must be between 0 and 100"),
    yop_12: Yup.number()
      .required("Year of Passing is required")
      .min(1900, "Year must be a valid year"),
    // certificate_12: Yup.mixed()
    //   .required("Certificate is required")
    //   .test(
    //     "fileType",
    //     "Invalid file type. Only JPG, PNG, PDF, DOC, DOCX are allowed.",
    //     (value) => {
    //       if (!value) return false;
    //       const allowedTypes = [
    //         "image/jpeg",
    //         "image/png",
    //         "application/pdf",
    //         "application/msword",
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       ];
    //       return allowedTypes.includes(value.type);
    //     }
    //   ),
    board_ug: Yup.string().required("Board is required"),
    percentage_ug: Yup.number()
      .required("Percentage is required")
      .min(0, "Percentage must be a positive number")
      .max(100, "Percentage must be between 0 and 100"),
    yop_ug: Yup.number()
      .required("Year of Passing is required")
      .min(1900, "Year must be a valid year"),
    // certificate_ug: Yup.mixed()
    //   .required("Certificate is required")
    //   .test(
    //     "fileType",
    //     "Invalid file type. Only JPG, PNG, PDF, DOC, DOCX are allowed.",
    //     (value) => {
    //       if (!value) return false;
    //       const allowedTypes = [
    //         "image/jpeg",
    //         "image/png",
    //         "application/pdf",
    //         "application/msword",
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       ];
    //       return allowedTypes.includes(value.type);
    //     }
    //   ),
  });

  const initialValues = {
    name: "",
    address: "",
    email: "",
    phone_no: "",
    gender: "",
    is_international_student: "",
    nationality: "",
    residence_proof: "",
    application_type: "march",
    board_10: "",
    percentage_10: "",
    yop_10: "",
    certificate_10: "",
    board_12: "",
    percentage_12: "",
    yop_12: "",
    certificate_12: "",
    board_ug: "",
    percentage_ug: "",
    yop_ug: "",
    certificate_ug: "",
    amount: "",
    coa_reg_no: "",
    total_no_of_years: "",
    industry: "",
    job_description: "",
  };

  // Formik setup
  const formik = useFormik({
    initialValues: initialValues, // Replace with your form's initial values
    validationSchema:
      step === 1 ? validationSchemaStep1 : validationSchemaStep2,
    onSubmit: async (values) => {
      console.log("Form submitted with values:", values);

      // Merge form values with any additional data
      const orderData = { ...data, ...values };
      console.log("Merged form data:", orderData);

      // Create FormData object
      const formData = new FormData();
      Object.entries(orderData).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, value);
        }
      });

      try {
        // Trigger Razorpay display (awaiting the async operation)
        const razorpayOrder = await getRazorPayOrder(formData);
        console.log("Razorpay Order:", razorpayOrder);
        navigate("/success");
      } catch (error) {
        console.error("Error triggering Razorpay:", error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    AOS.init(); // Initialize AOS animation
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...data, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...data, certificate_10: e.target.files[0] });
  };

  const handleNext = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
      } else {
        formik.setTouched(errors);
      }
    });
  };

  // Handle "Previous" button
  const handlePrevious = () => {
    setStep(step - 1);
  };

  console.log("formik.errors", formik.errors);

  return (
    <div>
      {/* Start Page Banner */}
      <div className="page-banner-area bg-1">
        <div className="container">
          <div className="page-banner-content">
            <h1>M.Arch Application</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="navspace">M.Arch Application</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Page Banner */}

      {/* Application Form */}
      <div className="campus-information-area pb-70">
        <div className="container">
          <div className="row align-items-center mt-5">
            <h2 className="CarrersDetails">M.Arch Application</h2>

            <div className="col-lg-12">
              <div className="campus-content style-2">
                <form onSubmit={formik.handleSubmit} noValidate>
                  {step === 1 && (
                    <>
                      <h4>Personal Information</h4>
                      <div className="row">
                        {/* Name Field */}
                        <div className="col-6">
                          <div className="form-group mt-3">
                            <label htmlFor="name">Name</label>
                            <input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control mt-3"
                              placeholder="Enter Your Name"
                              {...formik.getFieldProps("name")}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.name}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* Address Field */}
                        <div className="col-6">
                          <div className="form-group mt-3">
                            <label htmlFor="address">Address</label>
                            <input
                              id="address"
                              name="address"
                              type="text"
                              className="form-control mt-3"
                              placeholder="Enter Your Address"
                              {...formik.getFieldProps("address")}
                            />
                            {formik.touched.address && formik.errors.address ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.address}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* row -2 */}
                      <div className="row">
                        {/* Email Field */}
                        <div className="col-6">
                          <div className="form-group mt-3">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              className="form-control mt-3"
                              placeholder="Enter Your Email"
                              {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.email}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Phone Field */}
                        <div className="col-6">
                          <div className="form-group mt-3">
                            <label htmlFor="phone">Phone Number</label>
                            <input
                              type="tel"
                              className="form-control mt-3"
                              placeholder="Enter Your Phone Number"
                              {...formik.getFieldProps("phone_no")}
                            />
                            {formik.touched.phone_no &&
                              formik.errors.phone_no && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.phone_no}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* row-3 */}
                      <div className="row">
                        {/* Gender Field */}
                        <div className="col-6">
                          <div className="form-group mt-3">
                            <h6>Gender&nbsp;&nbsp;&nbsp;</h6>
                            <div>
                              <input
                                type="radio"
                                name="gender"
                                value="1"
                                onChange={formik.handleChange}
                                checked={formik.values.gender === "1"}
                              />
                              <label className="gender">
                                Male&nbsp;&nbsp;&nbsp;
                              </label>

                              <input
                                type="radio"
                                name="gender"
                                value="2"
                                onChange={formik.handleChange}
                                checked={formik.values.gender === "2"}
                              />
                              <label className="gender">
                                Female&nbsp;&nbsp;&nbsp;
                              </label>

                              <input
                                type="radio"
                                name="gender"
                                value="3"
                                onChange={formik.handleChange}
                                checked={formik.values.gender === "3"}
                              />
                              <label className="gender">Other</label>
                            </div>
                            {formik.touched.gender && formik.errors.gender && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.gender}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* International Student Field */}
                        <div className="col-6">
                          <div className="form-group mt-3">
                            <h6>International Student&nbsp;&nbsp;&nbsp;</h6>
                            <div>
                              <input
                                type="radio"
                                name="is_international_student"
                                value="1"
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.is_international_student === "1"
                                }
                              />
                              <label className="gender">
                                Yes&nbsp;&nbsp;&nbsp;
                              </label>

                              <input
                                type="radio"
                                name="is_international_student"
                                value="2"
                                onChange={formik.handleChange}
                                checked={
                                  formik.values.is_international_student === "2"
                                }
                              />
                              <label className="gender">
                                No&nbsp;&nbsp;&nbsp;
                              </label>
                            </div>
                            {formik.touched.is_international_student &&
                              formik.errors.is_international_student && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.is_international_student}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* row-4 */}
                      <div className="row">
                        {/* Nationality Field */}
                        <div className="col-6">
                          <div className="form-group my-3">
                            <h6>Nationality</h6>
                            <input
                              type="text"
                              name="nationality"
                              className="form-control"
                              placeholder="Enter your Nationality"
                              {...formik.getFieldProps("nationality")}
                            />
                            {formik.touched.nationality &&
                              formik.errors.nationality && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.nationality}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Residence Proof Field */}
                        {/* <div className="col-6 mt-3">
                          <h6>
                            Residence Proof (Only for international students)
                          </h6>
                          <h6 className="text-blacks">
                            Note:
                            <span className="styleClass fontstyle">
                              {" "}
                              Submit documents with different file names. No two
                              documents should have the same file name.
                              <input
                                type="file"
                                className="form-control"
                                onChange={(event) => {
                                  const file = event.currentTarget.files[0];
                                  formik.setFieldValue("residence_proof", file);
                                }}
                              />
                              <span className="fontstyle">
                                (Upload your certificate from your device.
                                Acceptable formats are .docx or .pdf with a
                                maximum file size of 5MB.)
                              </span>
                            </span>
                          </h6>
                          {formik.errors.residence_proof && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block error">
                                {formik.errors.residence_proof}
                              </div>
                            </div>
                          )}
                        </div> */}
                      </div>
                      {/* Additional fields for step 1 */}
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <h4>Education</h4>
                      <h5>Class 10th</h5>
                      {/* row -1 */}
                      <div className="row">
                        {/* Board Input */}
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">Board *</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Board"
                              {...formik.getFieldProps("board_10")}
                            />
                            {formik.touched.board_10 &&
                              formik.errors.board_10 && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.board_10}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Percentage Input */}
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">Percentage *</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Percentage"
                              {...formik.getFieldProps("percentage_10")}
                            />
                            {formik.touched.percentage_10 &&
                              formik.errors.percentage_10 && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.percentage_10}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Year of Passing Input */}
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">Year of Passing *</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your YOP"
                              {...formik.getFieldProps("yop_10")}
                            />
                            {formik.touched.yop_10 && formik.errors.yop_10 && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.yop_10}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* row -2 */}
                      {/* <div className="row">
                        <div className="col-8 mt-3">
                          <h6>Upload certificate</h6>
                          <h6 className="text-blacks">
                            Note :
                            <span className="styleClass fontstyle">
                              {" "}
                              Submit documents with different file names. No two
                              documents should have the same file name.
                              <div className="form-group mt-3">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];

                                    // File validation: Check for file type and size
                                    const validFormats = [
                                      "application/pdf",
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    ];
                                    const maxSize = 5 * 1024 * 1024; // 5MB

                                    if (
                                      file &&
                                      validFormats.includes(file.type) &&
                                      file.size <= maxSize
                                    ) {
                                      formik.setFieldValue(
                                        "certificate_10",
                                        file
                                      );
                                    } else {
                                      if (!validFormats.includes(file.type)) {
                                        alert(
                                          "Invalid file type. Only .pdf and .docx are allowed."
                                        );
                                      } else if (file.size > maxSize) {
                                        alert("File size exceeds 5MB.");
                                      }
                                    }
                                  }}
                                />
                                {formik.touched.certificate_10 &&
                                  formik.errors.certificate_10 && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block error">
                                        {formik.errors.certificate_10}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <span className="fontstyle">
                                (Upload your certificate from your device.
                                Acceptable formats are .docx or .pdf with a
                                <br />
                                maximum file size of 5MB.)
                              </span>
                            </span>
                          </h6>
                        </div>
                        <div className="col-4"></div>
                      </div> */}
                      {/* row-3 */}
                      <div className="row mt-3">
                        <h5>Class 12th / Equivalent</h5>
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">Board *</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Board"
                              {...formik.getFieldProps("board_12")}
                            />
                            {formik.touched.board_12 &&
                              formik.errors.board_12 && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.board_12}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">Percentage *</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Percentage"
                              {...formik.getFieldProps("percentage_12")}
                            />
                            {formik.touched.percentage_12 &&
                              formik.errors.percentage_12 && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.percentage_12}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">Year of Passing *</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your YOP"
                              {...formik.getFieldProps("yop_12")}
                            />
                            {formik.touched.yop_12 && formik.errors.yop_12 && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.yop_12}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* row-4 */}
                      {/* <div className="row">
                        <div className="col-8 mt-3">
                          <h6>Upload certificate</h6>
                          <h6 className="text-blacks">
                            Note :
                            <span className="styleClass fontstyle">
                              {" "}
                              Submit documents with different file names. No two
                              documents should have the same file name.
                              <div className="form-group mt-3">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];

                                    // File validation: Check for file type and size
                                    const validFormats = [
                                      "application/pdf",
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    ];
                                    const maxSize = 5 * 1024 * 1024; // 5MB

                                    if (
                                      file &&
                                      validFormats.includes(file.type) &&
                                      file.size <= maxSize
                                    ) {
                                      formik.setFieldValue(
                                        "certificate_12",
                                        file
                                      );
                                    } else {
                                      if (!validFormats.includes(file.type)) {
                                        alert(
                                          "Invalid file type. Only .pdf and .docx are allowed."
                                        );
                                      } else if (file.size > maxSize) {
                                        alert("File size exceeds 5MB.");
                                      }
                                    }
                                  }}
                                />
                                {formik.touched.certificate_12 &&
                                  formik.errors.certificate_12 && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block error">
                                        {formik.errors.certificate_12}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <span className="fontstyle">
                                (Upload your certificate from your device.
                                Acceptable formats are .docx or .pdf with a
                                <br />
                                maximum file size of 5MB.)
                              </span>
                            </span>
                          </h6>
                        </div>
                        <div className="col-4"></div>
                      </div> */}
                      {/* row-5 */}
                      <div className="row">
                        <h5 className="mt-3">Undergraduate</h5>

                        {/* College Name */}
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">
                              College Name *&nbsp;&nbsp;&nbsp;
                            </h6>
                            <input
                              type="text"
                              id="board_ug"
                              className="form-control"
                              placeholder="Enter Your College Name"
                              {...formik.getFieldProps("board_ug")}
                            />
                            {formik.touched.board_ug &&
                              formik.errors.board_ug && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.board_ug}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Percentage */}
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">
                              Percentage *&nbsp;&nbsp;&nbsp;
                            </h6>
                            <input
                              type="number"
                              id="percentage_ug"
                              className="form-control"
                              placeholder="Enter Your Board"
                              {...formik.getFieldProps("percentage_ug")}
                            />
                            {formik.touched.percentage_ug &&
                              formik.errors.percentage_ug && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.percentage_ug}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Year of Passing */}
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">
                              Year of Passing *&nbsp;&nbsp;&nbsp;
                            </h6>
                            <input
                              type="number"
                              id="yop_ug"
                              className="form-control"
                              placeholder="Enter Your YOP"
                              {...formik.getFieldProps("yop_ug")}
                            />
                            {formik.touched.yop_ug && formik.errors.yop_ug && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.yop_ug}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* row -6 */}
                      {/* <div className="row">
                        <div className="col-8 mt-3">
                          <h6>Upload certificate</h6>
                          <h6 className="text-blacks">
                            Note :
                            <span className="styleClass fontstyle">
                              {" "}
                              Submit documents with different file names. No two
                              documents should have the same file name.
                              <div className="form-group mt-3">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];

                                    // File validation: Check for file type and size
                                    const validFormats = [
                                      "application/pdf",
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    ];
                                    const maxSize = 5 * 1024 * 1024; // 5MB

                                    if (
                                      file &&
                                      validFormats.includes(file.type) &&
                                      file.size <= maxSize
                                    ) {
                                      formik.setFieldValue(
                                        "certificate_ug",
                                        file
                                      );
                                    } else {
                                      if (!validFormats.includes(file.type)) {
                                        alert(
                                          "Invalid file type. Only .pdf and .docx are allowed."
                                        );
                                      } else if (file.size > maxSize) {
                                        alert("File size exceeds 5MB.");
                                      }
                                    }
                                  }}
                                />
                                {formik.touched.certificate_ug &&
                                  formik.errors.certificate_ug && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block error">
                                        {formik.errors.certificate_ug}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <span className="fontstyle">
                                (Upload your certificate from your device.
                                Acceptable formats are .docx or .pdf with a
                                <br />
                                maximum file size of 5MB.)
                              </span>
                            </span>
                          </h6>
                        </div>
                        <div className="col-4"></div>
                      </div> */}
                      {/* row-7 */}
                      <div className="row">
                        <div className="col-8 mt-3">
                          <div className="form-group mt-3">
                            <h6>COA Registration Number &nbsp;&nbsp;&nbsp;</h6>
                            <input
                              type="text"
                              id="coa_reg_no"
                              name="coa_reg_no"
                              className="form-control"
                              placeholder="Enter Your COA Registration No"
                              value={formik.values.coa_reg_no}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.coa_reg_no &&
                              formik.errors.coa_reg_no && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.coa_reg_no}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-4 mt-3">
                          <div className="form-group mt-3">
                            <h6>Amount &nbsp;&nbsp;&nbsp;</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Amount"
                              {...formik.getFieldProps("amount")}
                            />
                            {formik.touched.amount && formik.errors.amount && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block error">
                                  {formik.errors.amount}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* row-8 */}
                      <div className="row">
                        <h5 className="mt-3">Experience</h5>
                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">
                              Total no. of Years &nbsp;&nbsp;&nbsp;
                            </h6>
                            <input
                              type="number"
                              id="total_no_of_years"
                              name="total_no_of_years"
                              className="form-control"
                              placeholder="Enter Your Total No.Of Years"
                              value={formik.values.total_no_of_years}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.total_no_of_years &&
                              formik.errors.total_no_of_years && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.total_no_of_years}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">
                              Industry&nbsp;&nbsp;&nbsp;
                            </h6>
                            <input
                              type="text"
                              id="industry"
                              name="industry"
                              className="form-control"
                              placeholder="Enter Your Industry"
                              value={formik.values.industry}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.industry &&
                              formik.errors.industry && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.industry}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group mt-3">
                            <h6 className="styleClass">
                              Job Description&nbsp;&nbsp;&nbsp;
                            </h6>
                            <textarea
                              id="job_description"
                              name="job_description"
                              className="form-control"
                              placeholder="Enter Your Job Description"
                              value={formik.values.job_description}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.job_description &&
                              formik.errors.job_description && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block error">
                                    {formik.errors.job_description}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* Additional fields for step 2 */}
                    </>
                  )}

                  <div className="modal-footer mt-3">
                    <div className="row">
                      {step > 1 && (
                        <div className={step === 2 ? "col-6" : "col-12"}>
                          <button
                            type="button"
                            className="default-btn btn btn-block"
                            onClick={handlePrevious}
                          >
                            Previous
                          </button>
                        </div>
                      )}
                      {step < 2 && (
                        <div className="col-12">
                          <button
                            type="button"
                            className="default-btn btn btn-block"
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      )}
                      {step === 2 && (
                        <div className="col-6">
                          <button
                            type="submit"
                            className="default-btn btn btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <style>
                  {`
          .error {
            color: red;
            font-size: 0.875rem;
            margin-top: 4px;
          }
        `}
                </style>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Application Form */}
    </div>
  );
};
