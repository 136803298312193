import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { listNews } from "../server/apihelper";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const News = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const query = "page=1&items_per_page=10";
    listNews(query).then((res) => {
      setNewsList(res.data);
    });
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // Formats month as 'Mar', 'Apr', etc.
    return { day, month };
  };
  return (
    <>
      {/* Add SEO Tags */}
      <div>
        <Helmet>
          <title>
            Latest Architecture College News and Events at McGAN’S Ooty School
            of Architecture | Top Architecture College in Tamil Nadu
          </title>
          <meta
            name="description"
            content="Explore the latest news and events at McGAN’S Ooty School of Architecture, one of the leading architecture colleges in Tamil Nadu. Stay informed about exciting events like Graduation Day, Art Exhibits, Cultural Fests, and more, hosted by our prestigious institution."
          />
          <meta
            name="keywords"
            content="Architecture College News Tamil Nadu, 
McGAN’S Ooty News, 
Latest Architecture Events, 
McGAN’S Cultural Fest 2023, 
McGAN’S Graduation Day 2023, 
World Record Event Ooty, 
Architecture College Events, 
McGAN’S Alumni Meet 2023, 
McGAN’S Ooty Events, 
Best Architecture College Tamil Nadu, 
Architecture Events in Ooty"
          />
          <meta property="og:title" content="Page Title" />
          <meta
            property="og:description"
            content="This is the page description for Open Graph"
          />
          <meta property="og:image" content="https://mcgansarch.com/" />
          <link rel="canonical" href="https://mcgansarch.com/news" />
        </Helmet>
        <h1 style={{ display: "none" }}>
          Latest Architecture College News and Upcoming Events at McGAN’S Ooty
          School of Architecture | Top Tamil Nadu College
        </h1>
      </div>
      <div>
        {/* Start Page Banner */}
        <div className="page-banner-area bg-1">
          <div className="container">
            <div className="page-banner-content">
              <h3 className="BannerHeader">News</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="navspace">News</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner */}

        {/* Start Campus Information */}
        <div className="campus-life-area pt-100 pb-70">
          <div className="container">
            <div className="row justify-content-left">
              <h5>NEWS AT McGAN’S</h5>
              {/* Event Cards */}
              {newsList.map((item, index) => {
                const { day, month } = formatDate(item.date);
                return (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <div className="single-events-card style-4">
                      <div className="events-image">
                        <Link
                          to={`/news-details/${item?.primaryId}`}
                          className="read-more-btn"
                        >
                          <img
                            src={item.image}
                            style={{
                              width: "356px",
                              height: "251px",
                            }}
                            title="McGAN’S Ooty News"
                            alt="McGAN’S Ooty News"
                          />
                        </Link>
                        <div className="date">
                          <span>{day}</span>
                          <p>{month}</p>
                        </div>
                      </div>
                      <div className="events-content">
                        <div className="admin">
                          <p>
                            <a>
                              <i className="ri-map-pin-line"></i>
                              {item.place}
                            </a>
                          </p>
                        </div>
                        <Link
                          to={`/news-details/${item?.primaryId}`}
                          className="read-more-btn"
                        >
                          <h3>{item.title}</h3>
                        </Link>
                        <Link
                          to={`/news-details/${item?.primaryId}`}
                          className="read-more-btn"
                        >
                          Read More<i className="flaticon-next"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* End Campus Information */}
      </div>
    </>
  );
};
